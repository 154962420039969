// Authors: S.Bechtold, F.Schmenger
/**
 * This file contains wrapper definitions for OpenLayers working as an
 * abstraction layer to overcome differences between OpenLayers versions.
 */
/**
 * Imported OpenLayers symbols / aliases.
 */
import Event from "ol/events/Event";
import { equals as extentEquals } from "ol/extent";
import ImageLayer from "ol/layer/Image";
import ImageCanvasSource from "ol/source/ImageCanvas";
import Map from "ol/Map";
import Observable from "ol/Observable";
import Projection from "ol/proj/Projection";
import { equivalent as projEquivalent } from "ol/proj";
import { register as registerProj4 } from "ol/proj/proj4.js";
import { transformExtent } from "ol/proj";
/**
 * Register proj4 in OpenLayers 4.
 * OpenLayers 5 follows a different approach by invoking ol/proj/proj4.register
 * any time the proj4 definitions have changed.
 * Therefore the shim is a no-op.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
function setProj4(proj4) { }
/**
 * Exported OpenLayers symbols / aliases / shims.
 */
export { Event, extentEquals, ImageLayer, ImageCanvasSource, Map, Observable, Projection, projEquivalent, registerProj4, setProj4, transformExtent };
