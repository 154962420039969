// Authors: S.Bechtold, F.Schmenger
import { Observable } from "../lib/ES6/OpenLayers";
import { WmsFlowAnimationLayer } from "../layers/WmsFlowAnimationLayer";
import { WmsRasterAnimationLayer } from "../layers/WmsRasterAnimationLayer";
import { asyncTransformExtent } from "../util/Utils";
import { GeoServerAnimationApi } from "../geoserverRestApi/GeoServerAnimationApi";
import { ImagePool } from "../util/ImagePool";
import { ImageState } from "../util/ImageResource";
import { AnimationBeforeLoadEvent, AnimationFrameChangedEvent, AnimationStartedEvent, AnimationStoppedEvent, AnimationLoadStartEvent, AnimationLoadEndEvent, AnimationImageLoadEvent, AnimationImageLoadErrorEvent } from "./AnimationEvents";
/**
 * Type of Flood Area animations:
 * @api
 */
export var AnimationType;
(function (AnimationType) {
    /**
     * Raster animations, which are displayed on a single WmsRasterAnimationLayer.
     */
    AnimationType[AnimationType["Raster"] = 1] = "Raster";
    /**
     * Flow animations, which are displayed on a WmsRasterAnimationLayer and
     * WmsFlowAnimation layer simultaneously.
     */
    AnimationType[AnimationType["Flow"] = 2] = "Flow";
})(AnimationType || (AnimationType = {}));
/**
 * State of the current animation
 * @api
 */
export var AnimationState;
(function (AnimationState) {
    /**
     * The animation hasn't started loading.
     */
    AnimationState[AnimationState["Initializing"] = 1] = "Initializing";
    /**
     * One of the layers is currently loading images.
     */
    AnimationState[AnimationState["Loading"] = 2] = "Loading";
    /**
     * The animation is loaded and ready to play.
     */
    AnimationState[AnimationState["Ready"] = 3] = "Ready";
})(AnimationState || (AnimationState = {}));
/**
 * Animation object to list, load and display Flood Area animations on an Open Layers map.
 * @api
 * @fires {@link AnimationBeforeLoadEvent}
 * @fires {@link AnimationFrameChangedEvent}
 * @fires {@link AnimationStartedEvent}
 * @fires {@link AnimationStoppedEvent}
 * @fires {@link AnimationLoadStartEvent}
 * @fires {@link AnimationLoadEndEvent}
 * @fires {@link AnimationImageLoadEvent}
 * @fires {@link AnimationImageLoadErrorEvent}
 */
export class Animation extends Observable {
    /**
     * Create an animation object to be rendered on an Open Layers map.
     * Internally this will create two layers, which can be obtained by
     * flowLayer and rasterLayer properties.
     * @param map Open layers map to render the animation on.
     * @param opt_options Optional animation options.
     * @constructor
     * @api
     */
    constructor(map, opt_options) {
        super();
        /**
         * WMS Styles in which the raster layer will be rendered, when displaying a flow animation.
         */
        this._stylesFlowDepth = "FloodAreaFlowDepth";
        /**
         * WMS Styles in which the raster layer will be rendered, when displaying a raster animation.
         */
        this._stylesWaterDepth = "";
        /**
         * Number of frames contained in the currently loaded animation.
         */
        this._numFramesTotal = 0;
        /**
         * Animation frame speed in ms.
         */
        this._speed = 1000;
        /**
         * Speed of particle animation in ms.
         */
        this._particleSpeed = 80;
        /**
         * Index of the currently displayed animation frame.
         */
        this._currentFrameIndex = 0;
        /**
         * Timer handle to process animation steps.
         */
        this._animationIntervalHandle = null;
        /**
         * Timer handle to process particle animation steps.
         */
        this._particleAnimationIntervalHandle = null;
        /**
         * The current state of the animation. See AnimationState.
         */
        this._state = AnimationState.Initializing;
        /**
         * Indicates whether the animation is in started or paused state.
         */
        this._doAnimate = true;
        /**
         * Listener for the image load event of a layer source.
         */
        this._imageLoadStartListener = this.onImageLoadStart.bind(this);
        /**
         * Listener for the image load error event of a layer source.
         */
        this._imageLoadEndListener = this.onImageLoadEnd.bind(this);
        /**
         * Increment or decrement the current frame-index with overrun checks and update the layers.
         */
        this.animate = () => {
            this.currentFrameIndex++;
        };
        /**
         * Animate the particles on the flow layer.
         */
        this.animateParticles = () => {
            this.flowLayer.doParticleAnimationStep();
        };
        if (!map) {
            throw "Map is required.";
        }
        this._map = map;
        // Setup configuration properties.
        const options = opt_options ? opt_options : {};
        if (options.speed && options.speed > 0) {
            this._speed = options.speed;
        }
        if (options.particleSpeed && options.particleSpeed > 0) {
            this._particleSpeed = options.particleSpeed;
        }
        if (typeof options.autoStart !== "undefined") {
            this._doAnimate = options.autoStart;
        }
        if (typeof options.stylesFlowDepth !== "undefined") {
            this._stylesFlowDepth = options.stylesFlowDepth;
        }
        if (typeof options.stylesWaterDepth !== "undefined") {
            this._stylesWaterDepth = options.stylesWaterDepth;
        }
        // Create the image pool.
        this._imagePool = new ImagePool();
        this._imagePool.on("imageload", this.onImageLoad.bind(this));
        this._imagePool.on("imageloaderror", this.onImageLoadError.bind(this));
        // Create the layers.
        // Remarks: The styles option of the layers is for internal use
        // and is not exposed as a global option. The interpolation method
        // is only configurable for the raster layer, as anything else
        // but 'nearest neighbor' leads to direction decoding bugs on the
        // flow layer.
        const projection = this._map.getView().getProjection();
        this._rasterLayer = new WmsRasterAnimationLayer({
            title: "Raster-Animation Layer",
            projection: projection,
            interpolationMethod: options.interpolationMethod,
            wmsImageScaleFactor: options.wmsImageScaleFactor
        });
        this._flowLayer = new WmsFlowAnimationLayer({
            title: "Flow-Animation Layer",
            projection: projection,
            //interpolationMethod: options.interpolationMethod,
            wmsImageScaleFactor: options.wmsImageScaleFactor,
            particleColor: options.particleColor,
            particleDensity: options.particleDensity,
            particleSpeedScale: options.particleSpeedScale,
            traceFadeFactor: options.traceFadeFactor,
            traceWidth: options.traceWidth
        });
    }
    /**
     * Returns a list of animation meta info JSON objects representing
     * animations stored on the respective GeoServer.
     * @param geoserverUrl Base URL to the GeoServer.
     * @param animType Optional animation type filter.
     * @param user Optional GeoServer user. For security reasons use during
     *  development only!
     * @param password Optional GeoServer password. For security reasons use
     *  during development only!
     * @api
     */
    static list(geoserverUrl, animType, user, password) {
        const gsApi = new GeoServerAnimationApi(geoserverUrl, user, password);
        const prefixes = [];
        if (!animType || animType === AnimationType.Raster) {
            prefixes.push(this._rasterWorkspacePrefix);
        }
        if (!animType || animType === AnimationType.Flow) {
            prefixes.push(this._flowWorkspacePrefix);
        }
        return gsApi.getAnimations(prefixes);
    }
    /**
     * Finds an animation on the respective GeoServer and returns an animation
     * meta info JSON objct.
     * @param geoserverUrl Base URL to the GeoServer.
     * @param animType Optional animation type filter.
     * @param user Optional GeoServer user. For security reasons use during
     *  development only!
     * @param password Optional GeoServer password. For security reasons use
     *  during development only!
     * @api
     */
    static get(geoserverUrl, name, user, password) {
        const gsApi = new GeoServerAnimationApi(geoserverUrl, user, password);
        return gsApi.getAnimation(name);
    }
    /**
     * Load and initialize the animation.
     * The animation will be started after loading, unless autoStart in the options is turned off.
     * @param animationInfo An animation meta info JSON object returned with the Animation::list method.
     * @api
     */
    load(animationInfo) {
        if (!animationInfo) {
            throw "Animation info is required.";
        }
        if (this._animationInfo === animationInfo) {
            return;
        }
        // Unload the previously shown animation and assign the new animation info.
        this.unload();
        this._animationInfo = animationInfo;
        // Detect the animation type and the amount of frames.
        const workspaceName = animationInfo.workspace.name;
        if (workspaceName.startsWith(Animation._rasterWorkspacePrefix)) {
            this._animationType = AnimationType.Raster;
        }
        else if (workspaceName.startsWith(Animation._flowWorkspacePrefix)) {
            this._animationType = AnimationType.Flow;
        }
        else {
            throw "Unknown animation type.";
        }
        const pub = animationInfo.publishables.published;
        this._numFramesTotal = Array.isArray(pub) ? pub.length : 1;
        // Transform the extent and load the animation.
        const b = this._animationInfo.bounds;
        const sourceEpsg = b.crs.$;
        const projection = this._map.getView().getProjection();
        const sourceExtent = [b.minx, b.miny, b.maxx, b.maxy];
        const transformPromise = asyncTransformExtent(sourceExtent, sourceEpsg, projection.getCode());
        transformPromise.then(transformedExtent => {
            this.dispatchEvent(new AnimationBeforeLoadEvent(transformedExtent));
            this._rasterLayer.styles =
                this._animationType === AnimationType.Flow
                    ? this._stylesFlowDepth
                    : this._stylesWaterDepth;
            // Setup the layers and notifications required for this animation type.
            // In case of a raster animation the flow layer is not required and not
            // added to the map.
            this.forEachLayer(layer => {
                layer.getSource().on("imageloadstart", this._imageLoadStartListener);
                layer.getSource().on("imageloadend", this._imageLoadEndListener);
                layer.setExtent(transformedExtent);
                layer.imagePool = this._imagePool;
                layer.animation = this._animationInfo;
                this._map.addLayer(layer);
                layer.getSource().changed();
            });
        });
    }
    /**
     * Free all resources associated with the current animation and remove the
     * layers from the map.
     */
    unload() {
        // Reset internal states.
        this.stopInternal();
        this._imagePool.removeAll();
        this._animationInfo = null;
        this._currentFrameIndex = 0;
        this._numFramesTotal = 0;
        this._state = AnimationState.Initializing;
        // Remove all events and layers from the map - that might have been
        // set up by a previous call to load.
        this.forEachLayer(layer => {
            layer.getSource().un("imageloadstart", this._imageLoadStartListener);
            layer.getSource().un("imageloadend", this._imageLoadEndListener);
            layer.imagePool = null;
            layer.animation = null;
            this._map.removeLayer(layer);
        }, true);
    }
    /**
     * Starts the animation. Execution may be delayed until the animation has finished loading images.
     * @api
     */
    start() {
        this._doAnimate = true;
        this.startInternal();
    }
    /**
     * Stops / pauses the animation. The animation will not be started again before start() is invoked.
     * @api
     */
    stop() {
        this._doAnimate = false;
        this.stopInternal();
    }
    /**
     * Get the current animation state.
     * @api
     */
    get state() {
        return this._state;
    }
    /**
     * Get the index of the current animation frame.
     * @api
     */
    get currentFrameIndex() {
        return this._currentFrameIndex;
    }
    /**
     * Set the index of the current animation frame.
     * @api
     */
    set currentFrameIndex(val) {
        if (val >= this._numFramesTotal) {
            val = 0;
        }
        else if (val < 0) {
            val = this._numFramesTotal - 1;
        }
        this._currentFrameIndex = val;
        this.forEachLayer(layer => {
            layer.currentFrameIndex = this.currentFrameIndex;
        });
        this.dispatchEvent(new AnimationFrameChangedEvent(this._currentFrameIndex, this._numFramesTotal));
    }
    /**
     * Get the animation speed in ms.
     * @api
     */
    get speed() {
        return this._speed;
    }
    /**
     * Set the animation speed in ms.
     * @api
     */
    set speed(value) {
        if (value <= 0) {
            console.error("invalid value for animation speed");
            return;
        }
        this._speed = value;
        this.startInternal();
    }
    /**
     * Get the particle animation speed in ms.
     * @api
     */
    get particleSpeed() {
        return this._particleSpeed;
    }
    /**
     * Set the particle animation speed in ms.
     * @api
     */
    set particleSpeed(value) {
        if (value <= 0) {
            console.error("invalid value for particle Speed");
            return;
        }
        this._particleSpeed = value;
        this.startInternal();
    }
    /**
     * Returns the flow layer associated with the animation.
     * @api
     */
    get flowLayer() {
        return this._flowLayer;
    }
    /**
     * Returns the raster layer associated with the animation.
     * @api
     */
    get rasterLayer() {
        return this._rasterLayer;
    }
    /**
     * Returns the animation meta data.
     * @api
     */
    get animationInfo() {
        return this._animationInfo;
    }
    /**
     * Returns the type of the loaded animation.
     * @api
     */
    get animationType() {
        return this._animationType;
    }
    /**
     * Returns the total amount of frames contained in the animation.
     * @api
     */
    get numFramesTotal() {
        return this._numFramesTotal;
    }
    /**
     * Returns the current particle base color of the flow layer.
     * @api
     */
    get particleColor() {
        return this._flowLayer.particleColor;
    }
    /**
     * Sets the particle base color of the flow layer.
     * @api
     */
    set particleColor(value) {
        this._flowLayer.particleColor = value;
    }
    /**
     * Returns the current particle density of the flow layer.
     * @api
     */
    get particleDensity() {
        return this._flowLayer.particleDensity;
    }
    /**
     * Sets the particle density of the flow layer.
     * @api
     */
    set particleDensity(value) {
        this._flowLayer.particleDensity = value;
    }
    /**
     * Returns the current particle speed multiplier of the flow layer.
     * @api
     */
    get particleSpeedScale() {
        return this._flowLayer.particleSpeedScale;
    }
    /**
     * Sets the particle speed multiplier of the flow layer.
     * @api
     */
    set particleSpeedScale(value) {
        this._flowLayer.particleSpeedScale = value;
    }
    /**
     * Returns the current particle fading speed of the flow layer.
     * @api
     */
    get traceFadeFactor() {
        return this._flowLayer.traceFadeFactor;
    }
    /**
     * Sets the particle fading speed of the flow layer.
     * @api
     */
    set traceFadeFactor(value) {
        this._flowLayer.traceFadeFactor = value;
    }
    /**
     * Returns the current line width in pixels for a particle of the flow
     * layer.
     * @api
     */
    get traceWidth() {
        return this._flowLayer.traceWidth;
    }
    /**
     * Sets the line width in pixels for a particle of the flow layer.
     * @api
     */
    set traceWidth(value) {
        this._flowLayer.traceWidth = value;
    }
    /**
     * Invokes a function for all layers involved in the animation.
     * @param func Function to be executed on the layers.
     * @param all Invokes the function for all layers, not only active layers.
     */
    forEachLayer(func, all) {
        func(this._rasterLayer);
        if (all || this._animationType === AnimationType.Flow) {
            func(this._flowLayer);
        }
    }
    /**
     * Stops / pauses the animation timers - without modifying the _doAnimate flag.
     */
    stopInternal() {
        if (!this._animationIntervalHandle) {
            return;
        }
        clearInterval(this._animationIntervalHandle);
        this._animationIntervalHandle = null;
        if (this._particleAnimationIntervalHandle) {
            clearInterval(this._particleAnimationIntervalHandle);
            this._particleAnimationIntervalHandle = null;
        }
        this.dispatchEvent(new AnimationStoppedEvent());
    }
    /**
     * Starts the animation timers if loading has finished. If the animation
     * is in paused state only the current frame is refreshed.
     */
    startInternal() {
        if (this._state !== AnimationState.Ready) {
            return;
        }
        this.stopInternal();
        this.forEachLayer(layer => {
            layer.currentFrameIndex = this._currentFrameIndex;
        });
        if (!this._doAnimate) {
            return;
        }
        this._animationIntervalHandle = setInterval(this.animate, Math.abs(this._speed));
        if (this._animationType === AnimationType.Flow) {
            this._particleAnimationIntervalHandle = setInterval(this.animateParticles, this._particleSpeed);
        }
        this.dispatchEvent(new AnimationStartedEvent());
    }
    /**
     * Checks if all layers have finished loading.
     */
    checkLoadComplete() {
        let complete = true;
        this.forEachLayer(layer => {
            complete = complete && layer.isReady;
        });
        return complete;
    }
    /**
     * Stop the animation, once one of the sources has started loading images.
     */
    onImageLoadStart() {
        if (this._state === AnimationState.Loading) {
            return;
        }
        this._state = AnimationState.Loading;
        this.stopInternal();
        this.dispatchEvent(new AnimationLoadStartEvent());
    }
    /**
     * Start the animation, if all sources involved in the animation have
     * finished loading.
     */
    onImageLoadEnd() {
        if (!this.checkLoadComplete()) {
            return;
        }
        this._state = AnimationState.Ready;
        this.dispatchEvent(new AnimationLoadEndEvent());
        this.startInternal();
    }
    /**
     * Estimate the amount of frames to be buffered based on the animation
     * speed and the current loading statistics. Then update the layers loading
     * state.
     */
    updateLayerLoading() {
        const speed = Math.abs(this._speed);
        const imagesPerFrame = this._animationType === AnimationType.Flow ? 2 : 1;
        const avgTime = this._imagePool.getAverageLoadTime() * imagesPerFrame;
        let numPreload = (1 - speed / avgTime) * this._numFramesTotal;
        numPreload = Math.max(3, numPreload);
        this.forEachLayer(layer => {
            layer.updateLoading(numPreload);
        });
    }
    /**
     * An animation frame has been succesfully loaded.
     * @param evt The image load event.
     */
    onImageLoad(evt) {
        this.dispatchEvent(new AnimationImageLoadEvent(evt.image.url));
        this.updateLayerLoading();
    }
    /**
     * Failed to load an animation frame.
     * Remarks: If image loading has been cancelled, the state is set back
     * to NotRequested. This is not reported as a load error to the
     * application.
     * @param evt The image load error event.
     */
    onImageLoadError(evt) {
        if (evt.image.state === ImageState.Error) {
            this.dispatchEvent(new AnimationImageLoadErrorEvent(evt.image.url));
        }
        this.updateLayerLoading();
    }
}
/**
 * Geoserver prefix for workspaces containing raster animations.
 */
Animation._rasterWorkspacePrefix = "anim_";
/**
 * Geoserver prefix for workspaces containing flow animations.
 */
Animation._flowWorkspacePrefix = "flow_";
