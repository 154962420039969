// Authors: S.Bechtold, F.Schmenger
import { ImageLayer, ImageCanvasSource, extentEquals, projEquivalent } from "../lib/ES6/OpenLayers";
/**
 * State of the layer
 */
export var LayerState;
(function (LayerState) {
    /**
     * The layer hasn't started loading.
     */
    LayerState[LayerState["Initializing"] = 1] = "Initializing";
    /**
     * The layer is currently loading images.
     */
    LayerState[LayerState["Loading"] = 2] = "Loading";
    /**
     * The layer is loaded and ready to play.
     */
    LayerState[LayerState["Ready"] = 3] = "Ready";
})(LayerState || (LayerState = {}));
/**
 * Base class for a canvas based Open Layers layer.
 * @abstract
 * @internal
 */
export class AbstractCanvasLayer extends ImageLayer {
    /**
     * Construction.
     * @param options Optional layer options.
     * @constructor
     */
    constructor(options) {
        super(options);
        /**
         * Canvas element representing the information drawn to the current layer.
         */
        this._canvas = document.createElement("canvas");
        /**
         * Rendering context associated with this layers canvas element.
         */
        this._ctx = (this._canvas.getContext("2d"));
        /**
         * Current canvas / layer size.
         */
        this._size = [0, 0];
        /**
         * The current state of the layer. See LayerState.
         */
        this._state = LayerState.Initializing;
        /**
         * Invoked by the Open Layers source to render the current image.
         * Remarks: Open Layers will precheck some conditions and will not invoke
         * this, if the layer is invisible or the visible extent of the viewport
         * does not intersect with the layers extent.
         * @param extent
         * @param resolution
         * @param pixelRatio
         * @param size
         * @param proj
         */
        this.getCanvas = (extent, resolution, pixelRatio, size, proj) => {
            // ATTENTION 'size' is NOT the screen pixel size of the view!!
            let doInit = false;
            this._resolution = resolution;
            // If extent has changed, reload images.
            if (this._visibleExtent == null ||
                !extentEquals(this._visibleExtent, extent)) {
                this._visibleExtent = extent;
                doInit = true;
            }
            // If the projection has changed, reload images.
            if (!this._proj || !projEquivalent(this._proj, proj)) {
                this._proj = proj;
                doInit = true;
            }
            // Update map viewport size and resize canvas if necessary.
            // Remarks: We must NOT resize the canvas on EVERY call of getCanvas()!
            // This breaks, for example, particle transparency/fade effects in FlodVisAnimationLayer.
            // Size is stored separately from canvas size, to maintain all of the
            // floating point decimals, as canvas sizes are generally rounded.
            if (size[0] != this._size[0] || size[1] != this._size[1]) {
                this._canvas.width = this._size[0] = size[0];
                this._canvas.height = this._size[1] = size[1];
                doInit = true;
            }
            // This is a workaround to compromise for the lack of a trigger from
            // OpenLayers, when the animation has changed without any other change in
            // the layers properties, i.e. extent.
            // The cause for this is, that derived layers are currently managing the
            // loading of images (instead of the layers source). The real OpenLayers
            // source cannot know about this and thus does not raise an update.
            if (this._state === LayerState.Initializing) {
                doInit = true;
            }
            // Reload the animation frames, if the visible extend or projection
            // has changed.
            if (doInit) {
                this.init();
            }
            // Remarks: We cannot refresh the frame via window.requestAnimation frame,
            // because the layers content would be drawn asynchronously while the
            // control flow has to return an updated canvas to OpenLayers right away!
            // This should be of no concern, because the canvas used here is generally
            // offscreen.
            this.draw();
            return this._canvas;
        };
        // Initially create layer source:
        this.createSource(options.projection);
    }
    /**
     * Create the OpenLayers source for this layer.
     */
    createSource(proj) {
        const sourceOpts = {
            canvasFunction: this.getCanvas,
            // Remarks: Projection property is supported by OL. See
            // https://openlayers.org/en/latest/apidoc/module-ol_source_ImageCanvas.html
            projection: proj,
            // Defines how much bigger the canvas should be compared to the viewport size
            // (invisible overlap to reduce amount of reloads when the map is panned just a bit):
            ratio: 1
        };
        super.setSource(new ImageCanvasSource(sourceOpts));
    }
    /**
     * Update the internal state.
     * @param state The new state of the layer.
     */
    setState(state) {
        if (this._state === state) {
            return;
        }
        this._state = state;
        if (this._state === LayerState.Loading) {
            super.getSource().dispatchEvent("imageloadstart");
        }
        else if (this._state === LayerState.Ready) {
            super.getSource().dispatchEvent("imageloadend");
        }
    }
}
