<template>
  <wgu-map-overlay
    overlayId="dss-district-hover-tooltip"
    :visible=false
    autoPan
    :autoPanDuration="250"
    :offset="[0, 25]"
    positioning="top-center"
    v-slot="{feature}"
  >
    <v-card
      width="300"
      v-if="feature">
      <v-img src="static/icon/flooddamage.jpg" height="100"/>
      <v-card-title>
        <span>
          {{ $t('dss-flooddamage.title') }}
        </span>
      </v-card-title>
      <v-card-subtitle>
        <span>
            {{ feature.get('name_district') }}
        </span>
        <br />
        <span>
          {{ $t('dss-flooddamage.hovercardClick') }}
        </span>
      </v-card-subtitle>
    </v-card>
  </wgu-map-overlay>
</template>

<script>
import MapOverlay from '@Wegue/components/modulecore/MapOverlay.vue'
export default {
  name: 'dss-district-hover-tooltip',
  components: {
    'wgu-map-overlay': MapOverlay
  }
}
</script>
