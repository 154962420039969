const ParamSerializerUtil = {
  /**
   * Utility method to check an object for type string. See
   * https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript
   * @param {Object} obj The object to check.
   * @returns True if the object is a string.
   */
  isString (obj) {
    return (Object.prototype.toString.call(obj) === '[object String]');
  },

  /**
   * Serialize Geoserver WFS vendor parameter 'viewParams' with respect to escaping.
   * @param {Object} viewParams View params as a set of key value pairs.
   * @param {Boolean} escapeStrings True to escape string params.
   * @returns A string containing the formatting view params.
   */
  serializeViewParams (viewParams, escapeStrings = true) {
    if (!viewParams) {
      return undefined;
    }

    return Object.entries(viewParams)
      .map(([key, value]) => {
        let escapedValue = value;
        if (escapeStrings && this.isString(value)) {
          escapedValue = `'${value}'`
            .replace(/,/g, '\\,')
            .replace(/;/g, '\\;');
        }
        return `${key}:${escapedValue}`;
      })
      .join(';')
  }
}

export default ParamSerializerUtil;
