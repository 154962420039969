<template>

  <wgu-module-card v-bind="$attrs"
    :moduleName="moduleName"
    class="dss-floodsim-win"
    :icon="icon"
    v-on:visibility-change="show"
    width=350>

    <v-subheader>{{ $t('dss-floodsim.scenario') }}</v-subheader>
    <v-card-text class="pt-0">
      <v-select
        color="secondary"
        item-color="secondary"
        v-model="animationId"
        :items="animationInfos"
        item-text="title"
        item-value="name"
        prepend-icon="mdi-home-thermometer-outline"
        menu-props="auto"
        dense
        hide-details>
      </v-select>
    </v-card-text>

    <v-card-actions>
      <v-btn-toggle color="secondary"
        :value="isAnimationActive">
        <v-btn
          :value="true"
          :title="$t('dss-floodsim.playAnimation')"
          @click="toggleAnimation()">
          <v-icon>mdi-animation-play</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>

    <v-expansion-panels :multiple="true" :accordion="true" class="dss-floodsim-exp-panel overflow-y-auto">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row align="center">
            <v-icon class="mr-4">settings</v-icon>
            {{ $t('dss-floodsim.animationOptions') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <dss-floodsim-animoptions
            :animation="animation"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <dss-floodsim-animcontrol v-if="isAnimationActive"
      :animation="animation" />
  </wgu-module-card>

</template>

<script>
import ModuleCard from '@Wegue/components/modulecore/ModuleCard';
import { Mapable } from '@Wegue/mixins/Mapable';
import ViewAnimationUtil from '@Wegue/util/ViewAnimation';
import { Animation, AnimationType } from '@Libs/olala/olala';
import AnimationOptions from './AnimationOptions';
import AnimationControl from './AnimationControl';
import { intersects } from 'ol/extent';
export default {
  name: 'dss-floodsim-win',
  inheritAttrs: false,
  mixins: [Mapable],
  components: {
    'wgu-module-card': ModuleCard,
    'dss-floodsim-animoptions': AnimationOptions,
    'dss-floodsim-animcontrol': AnimationControl
  },
  props: {
    icon: { type: String, required: false, default: 'weather-heavy-rain' },
    /**
     * Geoserver configuration.
     * For security reasons use credentials during development only!
     */
    geoServerUrl: { type: String, required: true },
    geoServerUser: { type: String, required: false, default: null },
    geoServerPwd: { type: String, required: false, default: null }
  },
  data () {
    return {
      moduleName: 'dss-floodsim',
      /**
       * Singleton OLALA animation wrapper.
       */
      animation: undefined,
      /**
       * The selected animation id (name).
       */
      animationId: undefined,
      /**
       * The selected animation record.
       */
      animationInfo: undefined,
      /**
       * The list of available animations.
       */
      animationInfos: [],
      /**
       * Filter for the type of animations. A design choice is to show Flow-
       * animations only.
       */
      animationType: AnimationType.Flow,
      /**
       * Activation state of the animation.
       */
      isAnimationActive: false
    }
  },
  methods: {
    /**
       * Unload the animation when the module is closed.
       * @param  {boolean} visible New visibility state
       */
    show (visible) {
      if (!visible) {
        this.isAnimationActive = false;
        this.animation.unload();
      }
    },
    /**
       * This function is executed, after the map is bound (see mixins/Mapable).
       * Customize legends and properties on the OLALA layers and zoom to the
       * extent, if it`s not partly visible.
       */
    onMapBound () {
      const me = this;
      me.animation = new Animation(me.map);
      me.animation.flowLayer.setProperties({
        lid: 'olala-flow',
        opacityControl: true
      });
      me.animation.rasterLayer.setProperties({
        lid: 'olala-raster',
        legend: true,
        legendOptions: { width: 14 },
        opacityControl: true
      })
      me.animation.on('beforeload', (evt) => {
        const view = me.map.getView();
        if (!intersects(view.calculateExtent(), evt.extent)) {
          ViewAnimationUtil.to(view, evt.extent);
        }
        me.animation.rasterLayer.set('legendUrl',
          me.getLegendUrl(me.animation.animationInfo));
      });
      me.requestAnimations();
    },
    /**
       * Format the WMS legend query to obtain an image for the raster layer.
       * TODO: Check if we can possibly introduce a getLegendUrl method on the
       *  OLALA source.
       */
    getLegendUrl (animationInfo) {
      const layerName = animationInfo.publishables?.published[0]?.name;
      return this.geoServerUrl + '/wms' +
        '?SERVICE=WMS' +
        '&VERSION=1.1.0' +
        '&REQUEST=GetLegendGraphic' +
        '&FORMAT=image/png' +
        '&LAYER=' + layerName +
        '&STYLE=FloodAreaFlowDepth' +
        '&WIDTH={{WIDTH}}' +
        '&LEGEND_OPTIONS={{LEGEND_OPTIONS}}' +
        '&TRANSPARENT={{TRANSPARENT}}' +
        '&LANGUAGE={{LANGUAGE}}';
    },
    /**
     * Request the list of available animations.
     * Remarks: This currently equivalent to the available scenarios but
     *  will be extended in the process, when damage computations are
     *  incorporated.
     */
    requestAnimations () {
      const me = this;

      Animation.list(
        me.geoServerUrl,
        me.animationType,
        me.geoServerUser,
        me.geoServerPwd
      ).then((animationInfos) => {
        me.animationInfos = animationInfos.sort((a, b) => {
          return a.title
            ? a.title.localeCompare(b.title)
            : 1;
        });
        if (me.animationInfos?.length > 0) {
          me.animationId = me.animationInfos[0].name;
        }
      })
        .catch(function (error) {
          me.alertCode = 'requestError';
          console.error(error);
        });
    },
    /**
     * The user has clicked the button to toggle the animation visibility.
     */
    toggleAnimation () {
      this.activateAnimation(!this.isAnimationActive);
    },
    /**
     * Show/hide the animation when repective play button is clicked.
     */
    activateAnimation (active) {
      if (!this.animation) {
        return;
      }
      if (this.isAnimationActive === active) {
        return;
      }
      this.isAnimationActive = active;

      if (this.isAnimationActive) {
        this.animation.load(this.animationInfo);
      } else {
        this.animation.unload();
      }
    }
  },

  watch: {
    /**
     * Watch for animationId selection change.
     */
    animationId (newVal) {
      if (!this.animation) {
        return;
      }

      this.animationInfo = this.animationInfos.find(s => s.name === newVal);
      if (this.isAnimationActive) {
        this.animation.load(this.animationInfo);
      }
    }
  }
}

</script>

<style scoped>
  .wgu-module-card.wgu-floating > .dss-floodsim-exp-panel {
    max-height: 500px;
  }
</style>
