// Authors: S.Bechtold, F.Schmenger
import { Observable, Event } from "../lib/ES6/OpenLayers";
import { ImageState } from "./ImageResource";
import { mod } from "./Utils";
/**
 * PoolImageLoadEvent is fired after an image has been succesfully loaded.
 * @internal
 */
export class PoolImageLoadEvent extends Event {
    /**
     * Construction.
     * @constructor
     */
    constructor(image) {
        super("imageload");
        this.image = image;
    }
}
/**
 * PoolImageLoadErrorEvent is fired after loading of an image failed.
 * @internal
 * @fires {@link PoolImageLoadEvent}
 * @fires {@link PoolImageLoadErrorEvent}
 */
export class PoolImageLoadErrorEvent extends Event {
    /**
     * Construction.
     * @constructor
     */
    constructor(image) {
        super("imageloaderror");
        this.image = image;
    }
}
/**
 * An image pool which handles asynchronous loading, request throttling and
 * serves as an image cache.
 * @internal
 */
export class ImagePool extends Observable {
    /**
     * Construction.
     * @constructor
     */
    constructor() {
        super();
        /**
         * Number of parallel image requests used by the pool. Browsers typically
         * support about 6-8 parallel connections for HTTP/1 servers and an
         * unlimited amount of connections for HTTP/2 servers.
         */
        this._maxConcurrentRequests = 6;
        /**
         * Number of pending requests.
         */
        this._pendingRequests = 0;
        /**
         * An array of images managed by the pool.
         */
        this._images = [];
        /**
         * For calculating average load time of the last N images.
         */
        this._runningSum = 0.0;
        this._runningLength = 0;
        this._runningIndex = 0;
        this._runningSamples = [0.0, 0.0, 0.0, 0.0, 0.0];
        this._runningAverage = 0.0;
    }
    /**
     * Return the average load time per image.
     */
    getAverageLoadTime() {
        if (this._runningLength === 0) {
            return NaN;
        }
        return this._runningAverage;
    }
    /**
     * Add an image resource to be managed by the pool.
     * @param image The image resource to add.
     */
    addImage(image) {
        this._images.push(image);
        this.requestImages();
    }
    /**
     * Remove an image resource from the pool and unloads it.
     * @param image The image resource to remove.
     */
    removeImage(image) {
        image.cancelLoading();
        const index = this._images.indexOf(image);
        if (index > -1) {
            this._images.splice(index, 1);
        }
    }
    /**
     * Remove all images from the pool.
     */
    removeAll() {
        for (const image of this._images) {
            image.cancelLoading();
        }
        this._images.length = 0;
    }
    /**
     * Find the next image to request until the maximum amount of concurrent
     * requests has been reached. If no such image exists the image pool has
     * fully loaded.
     */
    requestImages() {
        while (this._pendingRequests < this._maxConcurrentRequests) {
            let nextImage = null;
            let priority = NaN;
            for (const image of this._images) {
                if (image.state === ImageState.NotRequested &&
                    (!nextImage || image.priority < priority)) {
                    nextImage = image;
                    priority = image.priority;
                }
            }
            if (!nextImage) {
                break;
            }
            this.requestImage(nextImage);
        }
    }
    /**
     * Request an image resource.
     * @param image The image resource to request.
     */
    requestImage(image) {
        this._pendingRequests++;
        image.loadImage().then(() => {
            this.updateAverageLoadTime(image.loadTime);
            this.onFrameLoadSuccess(image);
            this._pendingRequests--;
            this.requestImages();
        }, () => {
            this.onFrameLoadError(image);
            this._pendingRequests--;
            this.requestImages();
        });
    }
    /**
     * An image has been succesfully loaded.
     * @param image Image data for the respective animation frame.
     */
    onFrameLoadSuccess(image) {
        this.dispatchEvent(new PoolImageLoadEvent(image));
    }
    /**
     * Failed to load an image.
     * @param image Image data for the respective animation frame.
     */
    onFrameLoadError(image) {
        this.dispatchEvent(new PoolImageLoadErrorEvent(image));
    }
    /**
     * Update loading time statistics from the 5 recent samples.
     * @param loadTime
     */
    updateAverageLoadTime(loadTime) {
        this._runningSum += loadTime;
        this._runningSum -= this._runningSamples[this._runningIndex];
        this._runningSamples[this._runningIndex] = loadTime;
        this._runningLength = Math.min(this._runningLength + 1, this._runningSamples.length);
        this._runningIndex = mod(this._runningIndex + 1, this._runningSamples.length);
        this._runningAverage = this._runningSum / this._runningLength;
    }
}
