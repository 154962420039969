import DamageBaseController from './DamageBaseController';
import WFSRequest from '../../util/WFSRequest';
import GeometrySerializerUtil from '../../util/GeometrySerializer';
import TileWmsSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import {
  and as andFilter,
  equalTo as equalToFilter
} from 'ol/format/filter';

export default class DamageBEAMController extends DamageBaseController {
  /**
   * Request the total damage to BEAM areas for the currently selected
   * scenario and area.
   * @param {Object} scenario The scenario info.
   * @param {ol.geom.Geometry} area The geometry of the selected area.
   * @param {axios.CancelTokenSource} cancelTokenSrc An optional cancel token to abort the request.
   */
  requestAreaDamage (scenario, area, cancelTokenSrc) {
    const me = this;
    return new Promise((resolve, reject) => {
      // Serialize the geometry as WKT, prefixed by 'SRID=xxxx;'.
      const viewProj = me.map.getView().getProjection();
      const wkt = GeometrySerializerUtil.serializePostgisWKT(
        area, viewProj, me.serviceConfig.projection);

      // Send the request
      WFSRequest.send(me.serviceConfig.baseUrl + '/ows', {
        featureTypes: ['f_asset_kind_damage'],
        outputFormat: 'application/json',
        viewParams: {
          id_scen: scenario.id_scen_beam,
          id_functionset: me.serviceConfig.idFunctionset,
          geom: wkt
        }
      }, null, cancelTokenSrc)
        .then(function (response) {
          const features = response.data.features;
          resolve(me.processFeatures(features));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  /**
   * Request the total damage to BEAM areas for the currently selected
   * scenario and city district.
   * @param {Object} scenario The scenario info.
   * @param {ol.feature} district The feature of the selected district.
   * @param {axios.CancelTokenSource} cancelTokenSrc An optional cancel token to abort the request.
   */
  requestDistrictDamage (scenario, district, cancelTokenSrc) {
    const me = this;
    return new Promise((resolve, reject) => {
      const districtId = district.get('id_district');

      // Send the request
      WFSRequest.send(me.serviceConfig.baseUrl + '/ows', {
        featureTypes: ['v_district_asset_kind_damage'],
        outputFormat: 'application/json',
        filter: andFilter(
          equalToFilter('id_scen', scenario.id_scen_beam),
          equalToFilter('id_functionset', me.serviceConfig.idFunctionset),
          equalToFilter('id_district', districtId)
        )
      }, null, cancelTokenSrc)
        .then(function (response) {
          const features = response.data.features;
          resolve(me.processFeatures(features));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  /**
   * Postprocess WFS response to return an ordered array of damage values.
   * @param {ol.feature[]} features
   */
  processFeatures (features) {
    if (!features) {
      return [];
    }
    return features.map(feat => feat.properties);
  }

  /**
   * Creates the layer displaying damage and asset values on a BEAM area level.
   * @param {Object} scenario The scenario info.
   */
  createMapLayer (scenario) {
    // BEAM asset value layer
    const sourceValue = new TileWmsSource({
      url: this.serviceConfig.baseUrl + '/wms',
      params: {
        LAYERS: 'flood_beam:fc_assets',
        TILED: true
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous'
    });
    this.wmsLayerValue = new TileLayer({
      lid: 'dss-flooddamage-beam-value-layer',
      source: sourceValue,
      opacity: 0.8,
      opacityControl: true,
      zIndex: 1,
      legend: true,
      visible: false
    });
    this.map.addLayer(this.wmsLayerValue);

    // BEAM damage layer
    const sourceDamage = new TileWmsSource({
      url: this.serviceConfig.baseUrl + '/wms',
      params: {
        LAYERS: `flood_beam:${scenario.beam_dmg_layer}`,
        TILED: true
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous'
    });
    this.wmsLayerDamage = new TileLayer({
      lid: 'dss-flooddamage-beam-damage-layer',
      source: sourceDamage,
      opacity: 0.8,
      opacityControl: true,
      zIndex: 1,
      // hoverable: true,
      // hoverOverlay: 'dss-flooddamage-beam-hover-tooltip',
      legend: true,
      legendOptions: {
        width: 14
      }
    });
    this.map.addLayer(this.wmsLayerDamage);

    // Inundation layer managed by base controller
    super.createMapLayer(scenario);
  };

  /**
   * Update the layer displaying damage on a BEAM area level, after the
   * selected scenario changes.
   * @param {Object} scenario The scenario info.
   */
  updateMapLayer (scenario) {
    super.updateMapLayer(scenario);
    if (this.wmsLayerDamage) {
      const source = this.wmsLayerDamage.getSource();
      const params = source.getParams();
      source.updateParams({
        ...params,
        LAYERS: `flood_beam:${scenario.beam_dmg_layer}`
      });
    }
  };
}
