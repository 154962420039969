<template>
  <wgu-module-card v-bind="$attrs"
      :moduleName="moduleName"
      class="dss-heat-win"
      v-on:visibility-change="show"
      width=400>

      <dss-heat-vulnerability-hover-tooltip
        :vulnLevels=vulnLevels
      />

      <v-subheader>{{ $t('dss-heat.scenario') }}</v-subheader>
      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="scenarioId"
          :items="scenarios"
          item-text="title"
          item-value="id"
          prepend-icon="mdi-home-thermometer-outline"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>

      <v-subheader>{{ $t('dss-heat.indicator') }}</v-subheader>
      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="indicatorId"
          :items="indicators"
          item-text="label"
          item-value="id"
          prepend-icon="mdi-gauge"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>
  </wgu-module-card>
</template>

<script>
import ModuleCard from '@Wegue/components/modulecore/ModuleCard';
import { Mapable } from '@Wegue/mixins/Mapable';
import HeatVulnerabilityTooltip from './HeatVulnerabilityTooltip';
import HeatController from './HeatController';
export default {
  name: 'dss-heat-win',
  inheritAttrs: false,
  mixins: [Mapable],
  components: {
    'wgu-module-card': ModuleCard,
    'dss-heat-vulnerability-hover-tooltip': HeatVulnerabilityTooltip
  },
  props: {
    /**
       * WFS/WMS parameters, such as the destination URL and projection for geometries.
       */
    services: { type: Object, required: true }
  },
  data () {
    return {
      moduleName: 'dss-heat',
      /**
        * The selected scenario id.
        */
      scenarioId: undefined,
      /**
         * The list of available scenarios.
         */
      scenarios: [],
      /**
         * The selected heat indicator id.
         */
      indicatorId: undefined,
      /**
         * The selected heat indicator record.
         */
      indicator: undefined,
      /**
         * The list of available heat indicators.
         */
      indicators: [],
      /**
         * The controller for WMS / WFS requests.
         */
      heatController: undefined,
      /**
         * Display names for the vulnerability levels enumeration.
         */
      vulnLevels: {}
    }
  },
  methods: {
    /**
       * This function is executed, after the map is bound (see mixins/Mapable).
       * Initialize the flood heat module.
       */
    onMapBound () {
      this.requestScenarios();
      this.createControllers();
      this.getVulnLevels();
      this.getIndicators(true);
    },
    /**
       * Loads module specific layers when the module is opened / closed.
       * @param  {boolean} visible New visibility state
       */
    show (visible) {
      if (visible) {
        this.createLayers();
      } else {
        this.removeLayers();
      }
    },
    /**
       * Create controllers for WMS / WFS requests.
       */
    createControllers () {
      this.heatController =
          new HeatController(this.map, this.services.heat)
    },
    /**
       * Initialize the heat indicator enumeration.
       * @param  {boolean} init Initially set the default selection.
       */
    getIndicators (init) {
      this.indicators = [
        {
          id: 0,
          label: this.$t('dss-heat.indicatorVulnTotal'),
          column: 'vul_ges'
        },
        {
          id: 1,
          label: this.$t('dss-heat.indicatorVulnCitizens'),
          column: 'vul_einw'
        },
        {
          id: 2,
          label: this.$t('dss-heat.indicatorVulnCitizens65'),
          column: 'vul_einw_65'
        },
        {
          id: 3,
          label: this.$t('dss-heat.indicatorVulnInstitutions'),
          column: 'vul_einr'
        }
      ];
      if (init) {
        this.indicatorId = this.indicators[0].id;
      }
    },
    /**
       * Initialize display names for the vulnearbility levels enumeration.
       * Available levels are 1-7, 1 is lowest.
       */
    getVulnLevels () {
      this.vulnLevels = {
        1: this.$t('dss-heat.level1'),
        2: this.$t('dss-heat.level2'),
        3: this.$t('dss-heat.level3'),
        4: this.$t('dss-heat.level4'),
        5: this.$t('dss-heat.level5'),
        6: this.$t('dss-heat.level6'),
        7: this.$t('dss-heat.level7')
      }
    },
    /**
       * Request the list of available scenarios.
       */
    requestScenarios () {
      const me = this;

      // TODO dummy, not yet implemented on the server side
      me.scenarios = [
        {
          id: 1,
          title: 'Gegenwart'
        }
      ];

      me.scenarioId = me.scenarios[0].id;
    },
    /**
       * Create module specific layers when the module is opened.
       */
    createLayers () {
      if (this.indicator) {
        this.heatController.createMapLayer(this.indicator);
      }
    },
    /**
       * Remove module specific layers when the module is closed.
       */
    removeLayers () {
      this.heatController.removeMapLayer();
    }
  },
  watch: {
    /**
       * Watch for heat indicator selection change.
       */
    indicatorId (newVal) {
      if (!this.heatController) {
        return;
      }

      this.indicator = this.indicators.find(s => s.id === newVal);
      this.heatController.updateMapLayer(this.indicator)
    },
    /**
       * Watch for locale changes.
       */
    '$i18n.locale': function () {
      this.getVulnLevels();
      this.getIndicators();
    }
  }
}
</script>
