// Authors: S.Bechtold, F.Schmenger
import { Event } from "../lib/ES6/OpenLayers";
/**
 * 'beforeload' is fired when the bounding extent of the animation
 * has been computed.
 * @api
 */
export class AnimationBeforeLoadEvent extends Event {
    /**
     * Construction.
     * @constructor
     * @param extent The bounding extent of the animation.
     */
    constructor(extent) {
        super("beforeload");
        this.extent = extent;
    }
}
/**
 * 'framechanged' is fired after the currently displayed animation
 * frame has changed.
 * @api
 */
export class AnimationFrameChangedEvent extends Event {
    /**
     * Construction.
     * @constructor
     * @param currentFrameIndex The currently displayed frame index of the animation.
     * @param numFramesTotal    Number of frames contained in the currently loaded animation.
     */
    constructor(currentFrameIndex, numFramesTotal) {
        super("framechanged");
        this.currentFrameIndex = currentFrameIndex;
        this.numFramesTotal = numFramesTotal;
    }
}
/**
 * 'started' is fired after animation timers have been started.
 */
export class AnimationStartedEvent extends Event {
    /**
     * Construction.
     * @constructor
     */
    constructor() {
        super("started");
    }
}
/**
 * 'stopped' is fired after animation timers have been stopped.
 */
export class AnimationStoppedEvent extends Event {
    /**
     * Construction.
     * @constructor
     */
    constructor() {
        super("stopped");
    }
}
/**
 * 'loadstart' is fired when one of layer sources involved in the
 * animation has to load images.
 */
export class AnimationLoadStartEvent extends Event {
    /**
     * Construction.
     * @constructor
     */
    constructor() {
        super("loadstart");
    }
}
/**
 * 'loadend' is fired when all layers sources involved in the
 * animation have finished loading images.
 */
export class AnimationLoadEndEvent extends Event {
    /**
     * Construction.
     * @constructor
     */
    constructor() {
        super("loadend");
    }
}
/**
 * 'imageload' fires when one of layer sources involved in the
 * animation has succefully loaded an image.
 */
export class AnimationImageLoadEvent extends Event {
    /**
     * Construction.
     * @constructor
     * @param url URL to the image data of the respective animation frame.
     */
    constructor(url) {
        super("imageload");
        this.url = url;
    }
}
/**
 * 'imageloaderror' fires when one of layer sources involved in the
 * animation failed to load an image.
 * @api
 */
export class AnimationImageLoadErrorEvent extends Event {
    /**
     * Construction.
     * @constructor
     * @param url URL to the image data of the respective animation frame.
     */
    constructor(url) {
        super("imageloaderror");
        this.url = url;
    }
}
