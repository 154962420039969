<template>
  <wgu-module-card v-bind="$attrs"
      :moduleName="moduleName"
      class="dss-lowwater-win"
      v-on:visibility-change="show"
      width=400>

      <dss-lowwater-region-hover-tooltip />

      <!-- Options / Filter section -->
      <v-subheader>{{ $t('dss-lowwater.scenario') }}</v-subheader>
      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="scenarioId"
          :items="scenarios"
          item-text="title"
          item-value="id"
          prepend-icon="mdi-home-thermometer-outline"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>

      <v-subheader>{{ $t('dss-lowwater.indicator') }}</v-subheader>
      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="indicatorId"
          :items="indicators"
          item-text="label"
          item-value="id"
          prepend-icon="mdi-gauge"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>

      <v-subheader>
        {{ categoryTypes[categoryType] }}
      </v-subheader>

      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="categoryId"
          :items="categories[categoryType]"
          item-text="label"
          item-value="id"
          clearable
          prepend-icon="mdi-shape"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>

      <v-subheader> {{ $t('dss-lowwater.region') }}</v-subheader>

      <v-card-actions>
        <v-row align="center" justify="center" no-gutters>
          <v-container>
          <dss-featurepicker
              :tooltip="$t('dss-lowwater.selectRegion')"
              layerId="dss-lowwater-regions"
              v-model="selectedRegions"
              resetButton
              :resetTooltip="$t('dss-lowwater.resetRegion')"
          />
          </v-container>
          <v-col class="px-2" align-self="center">
            <v-progress-linear
              color="secondary"
              :active="loading"
              buffer-value="0"
              stream
            ></v-progress-linear>
          </v-col>
          <v-col sm="12" md="12" lg="12">
            <v-alert
              v-model="alertVisible"
              :type="alertType"
              dismissible
              dense
              transition="scroll-y-transition"
              class="mt-2 mb-0"
              >
              {{ alertMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-actions>

      <!-- Result section -->
      <div v-if="result.length">
        <v-subheader> {{ resultText }} </v-subheader>
        <dss-lowwater-barchart
          :dataSource="barChartDataSource"
          :seriesColumns="barChartSeries"
          labelColumn="groupLabel"
          :colors="chartColors"
          :loading="loading"
          />
      </div>
  </wgu-module-card>
</template>

<script>
import ModuleCard from '@Wegue/components/modulecore/ModuleCard';
import { Mapable } from '@Wegue/mixins/Mapable';
import BarChart from './BarChart'
import RegionHoverTooltip from './RegionHoverTooltip'
import LowWaterController from './LowWaterController'
import FeaturePicker from '../uicontrols/featurePicker/FeaturePicker';
import WFSRequest from '../../util/WFSRequest';
import axios from 'axios';
export default {
  name: 'dss-lowwater-win',
  inheritAttrs: false,
  mixins: [Mapable],
  components: {
    'wgu-module-card': ModuleCard,
    'dss-lowwater-barchart': BarChart,
    'dss-lowwater-region-hover-tooltip': RegionHoverTooltip,
    'dss-featurepicker': FeaturePicker
  },
  props: {
    /**
       * WFS/WMS parameters, such as the destination URL and projection for geometries.
       */
    services: { type: Object, required: true }
  },
  data () {
    return {
      moduleName: 'dss-lowwater',
      /**
         * The controller for WMS / WFS requests.
         */
      lowWaterController: undefined,
      /**
         * The selected economical indicator id.
         */
      indicatorId: undefined,
      /**
         * The list of available economical indicators.
         **/
      indicators: [],
      /**
        * The selected scenario id.
        */
      scenarioId: undefined,
      /**
         * The list of available scenarios.
         */
      scenarios: [],
      /**
         * The scenario record used as a comparison scenario.
         */
      scenarioCmp: undefined,
      /**
         * The selected category id.
         */
      categoryId: undefined,
      /*
         * The list of available categories.
         * This is a 2 dimensional array containing the categories of
         * 'goods' and 'industry'.
         */
      categories: {},
      /**
         * Display names for category types.
         **/
      categoryTypes: {},
      /**
         * Display names for effect types.
         */
      effectTypes: {},
      /**
         * The features corresponding to the selected regions.
         */
      selectedRegions: undefined,
      /**
         * The feature containing the recently requested region.
         * This has to be stored separetely from selectedRegions,
         * in case of a selection reset.
         */
      requestedRegion: undefined,
      /**
         * The display type of the chart, which indicates how the result data
         * is grouped.
         * This can be either 'category', 'region'
         */
      chartType: undefined,
      /**
         * Object containing the computed result.
         */
      result: [],
      /**
         * Computing state.
         */
      loading: false,
      /**
         * List of errors / warnings
         */
      alertTypes: {
        requestError: 'error',
        noDataWarning: 'warning'
      },
      /**
         * Binding for visibility of the alert box.
         */
      alertVisible: false,
      /**
         * Currently displayed error / warning message.
         */
      alertCode: null,
      /**
         * Cancel token source to cancel pending requests.
         */
      pendingRequestsCancelSrc: null
    }
  },
  methods: {
    /**
       * This function is executed, after the map is bound (see mixins/Mapable).
       * Initialize the low water module.
       */
    onMapBound () {
      this.requestScenarios();
      this.createControllers();
      this.getIndicators(true);
      this.getCategories();
      this.getCategoryTypes();
      this.getEffectTypes();
    },
    /**
       * Loads module specific layers when the module is opened / closed.
       * @param  {boolean} visible New visibility state
       */
    show (visible) {
      if (visible) {
        this.createLayers();
      } else {
        this.removeLayers();
      }
    },
    /**
       * Create controllers for WMS / WFS requests.
       */
    createControllers () {
      this.lowWaterController =
          new LowWaterController(this.map, this.services.lowWater)
    },
    /**
       * Initialize the economical indicator enumeration.
       * @param  {boolean} init Initially set the default selection.
       */
    getIndicators (init) {
      this.indicators = [
        {
          id: 1,
          label: this.$t('dss-lowwater.indicatorImportVol'),
          categoryType: 'goods'
        },
        {
          id: 2,
          label: this.$t('dss-lowwater.indicatorEmployees'),
          categoryType: 'industry'
        },
        {
          id: 3,
          label: this.$t('dss-lowwater.indicatorGrossValue'),
          categoryType: 'industry'
        }
      ];
      if (init) {
        this.indicatorId = this.indicators[0].id;
      }
    },
    /**
       * Initialize display names for the goods categories enumeration.
       */
    getCategories () {
      // TODO Prognos will enhance this and provide proper translations.
      const categoriesGoods = [
        {
          id: 1,
          label: this.$t('dss-lowwater.goodsAgriculture')
        },
        {
          id: 2,
          label: this.$t('dss-lowwater.goodsCoal')
        },
        {
          id: 3,
          label: this.$t('dss-lowwater.goodsOres')
        },
        {
          id: 4,
          label: this.$t('dss-lowwater.goodsFood')
        },
        {
          id: 5,
          label: this.$t('dss-lowwater.goodsTextiles')
        }
      ];
      const categoriesIndustry = [
        {
          id: 1,
          label: this.$t('dss-lowwater.industryAgriculture')
        },
        {
          id: 2,
          label: this.$t('dss-lowwater.industryForestry')
        },
        {
          id: 3,
          label: this.$t('dss-lowwater.industryFishery')
        },
        {
          id: 5,
          label: this.$t('dss-lowwater.industryMining')
        },
        {
          id: 10,
          label: this.$t('dss-lowwater.industryFood')
        }
      ];

      this.categories = {
        goods: categoriesGoods,
        industry: categoriesIndustry
      }
    },
    /**
       * Initialize display names for category types.
       */
    getCategoryTypes () {
      this.categoryTypes = {
        goods: this.$t('dss-lowwater.categoryGoods'),
        industry: this.$t('dss-lowwater.categoryIndustry')
      }
    },
    /**
       * Initialize display names for effect types.
       */
    getEffectTypes () {
      this.effectTypes = {
        total: this.$t('dss-lowwater.effectTotal'),
        direct: this.$t('dss-lowwater.effectDirect'),
        indirect: this.$t('dss-lowwater.effectIndirect')
      }
    },
    /**
       * Request the list of available scenarios.
       */
    requestScenarios () {
      const me = this;

      WFSRequest.send(this.services.lowWater.baseUrl + '/ows', {
        featureTypes: ['tab_scenario'],
        outputFormat: 'application/json'
      })
        .then(function (response) {
          const features = response.data.features;
          me.scenarios = features
            .map(feat => ({
              id: parseInt(feat.id.split('.')[1]),
              ...feat.properties
            }))
            .sort((a, b) => {
              return a.id - b.id;
            });
          // Remarks:
          // Here we assume that the first scenario in list refers to
          // 'Status Quo' which will be used as a static comparison scenario
          // for now.
          if (me.scenarios?.length > 1) {
            me.scenarioCmp = me.scenarios.shift();
            me.scenarioId = me.scenarios[0].id;
          }
        })
        .catch(function (error) {
          me.alertCode = 'requestError';
          console.error(error);
        });
    },
    /**
       * Update the chart and displayed layer after changes to the selected
       * parameters.
       */
    update () {
      this.requestResult();
      this.updateLayers();
    },
    /**
       * Request the result for the currently selected
       * scenario and category or region.
       */
    requestResult () {
      if (!this.lowWaterController || !this.scenario || !this.scenarioCmp || !this.indicator) {
        return;
      }

      const me = this;
      let promise;
      let mode;
      const cancelToken = axios.CancelToken;

      // Cancel pending requests and create a new cancel token source which corresponds
      // to the async request sent in this iteration.
      if (this.pendingRequestsCancelSrc) {
        this.pendingRequestsCancelSrc.cancel();
      }
      this.pendingRequestsCancelSrc = cancelToken.source();

      // Here we have to distinguish some cases based on the set filters:
      // 1) If a category filter is set, the chart result is grouped by regions and
      // shows individual values related to each category.
      // 2) If a regional filter is set, the chart result is grouped by category and
      // shows individual values related to each region.
      // 3) If no filter is selected the chart result is grouped by category and
      // shows values aggrgated over all regions.
      if (this.category) {
        promise = this.lowWaterController.requestResult(
          this.scenario, this.scenarioCmp, this.indicator, this.category, this.requestedRegion,
          this.pendingRequestsCancelSrc
        );
        mode = 'region';
      } else if (this.requestedRegion) {
        promise = this.lowWaterController.requestResult(
          this.scenario, this.scenarioCmp, this.indicator, this.category, this.requestedRegion,
          this.pendingRequestsCancelSrc
        );
        mode = 'category';
      } else {
        promise = this.lowWaterController.requestCategoryResult(
          this.scenario, this.scenarioCmp, this.indicator,
          this.pendingRequestsCancelSrc
        );
        mode = 'category_agg'
      }
      me.chartType = mode;

      // Send the request
      if (promise) {
        me.loading = true;
        me.alertVisible = false;
        promise
          .then(result => {
            me.loading = false;
            me.result = result;
            me.alertCode =
              (result?.length > 0) ? false : 'noDataWarning';
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              me.loading = false;
              me.alertCode = 'requestError';
              console.error(error);
            }
          });
      }
    },
    /**
       * Create module specific layers when the module is opened.
       */
    createLayers () {
      // TODO:
      //  Initilization will fail when the module is initially
      //  visible, because scenarios have not been requested yet.
      if (!this.lowWaterController || !this.scenario || !this.scenarioCmp || !this.indicator) {
        return;
      }

      const layer = this.getLayerType(this.category);
      this.lowWaterController.createMapLayers(layer, this.scenario, this.scenarioCmp, this.indicator,
        this.category, this.requestedRegion);
    },
    /**
       * Remove module specific layers when the module is closed.
       */
    removeLayers () {
      this.lowWaterController.removeMapLayers();
    },
    /**
       * Update module specific layers when params have changed.
       */
    updateLayers () {
      if (!this.lowWaterController || !this.scenario || !this.scenarioCmp || !this.indicator) {
        return;
      }

      const layer = this.getLayerType(this.category);
      this.lowWaterController.updateMapLayers(layer, this.scenario, this.scenarioCmp, this.indicator,
        this.category, this.requestedRegion)
    },
    /**
       * Get the geoserver layer name to be displayed.
       * Here we have to distinguish the following cases based on the set filters:
       * 1) If a category filter is set, the layer shows individual values
       *   related to each category.
       * 2) If no category filter is selected, the layer shows values aggrgated
       *   over all categories.
       */
    getLayerType (category) {
      return category ? 'low_water:fv_economics_rel' : 'low_water:fv_region_economics_rel'
    }
  },
  computed: {
    /**
       * Returns the text displayed above the chart as a result. This depends
       * on the chart type.
       */
    resultText () {
      let text;
      if (this.chartType === 'category') {
        text = this.$t('dss-lowwater.categoryResult',
          [this.indicator.label, this.requestedRegion?.get('title'), this.categoryTypes[this.categoryType]])
      } else if (this.chartType === 'region') {
        text = this.$t('dss-lowwater.regionResult',
          [this.indicator.label, this.category?.label])
      } else if (this.chartType === 'category_agg') {
        text = this.$t('dss-lowwater.result',
          [this.indicator.label, this.categoryTypes[this.categoryType]])
      }
      return text;
    },
    /*
       * Returns tabular data to be displayed in the bar chart.
       * This contains one row per region or category.
       * The original dataset from the controllers is only altered to have
       * localized display names for effect types.
       */
    barChartDataSource () {
      const result = [...this.result]
        .sort((a, b) => {
          return a.value_rel - b.value_rel;
        })
        .map((row) => {
          let label;
          if (this.chartType === 'category' || this.chartType === 'category_agg') {
            const category = this.categories[this.categoryType].find(s => s.id === row.id_category);
            label = category?.label
          } else if (this.chartType === 'region') {
            label = row?.region_title;
          }
          return {
            groupLabel: label,
            [this.effectTypes.total]: row.value_rel,
            [this.effectTypes.direct]: row.value_direct_rel,
            [this.effectTypes.indirect]: row.value_indirect_rel
          };
        }, this);
      return result;
    },
    /**
       * Returns the series to be displayed in the bar chart.
       * For industry we only have 1 bar per group refering to the 'total' value.
       * For goods we are displaying 3 bars, related to 'total', 'direct' and
       * 'indirect' values.
       */
    barChartSeries () {
      return (this.categoryType === 'industry')
        ? Object.values(this.effectTypes)
        : [this.effectTypes.total];
    },
    /**
       * Returns an array of colors to be used in the chart.
       */
    chartColors () {
      return ['#E74C3C', '#3498DB', '#2980B9'];
    },
    /**
       * Return a localized error / warning message associated with the
       * current error code.
       */
    alertMessage () {
      return this.alertVisible
        ? this.$t(`dss-lowwater.alert.${this.alertCode}`)
        : '';
    },
    /**
       * Return the type of alert associated with the current error code.
       */
    alertType () {
      return this.alertTypes[this.alertCode];
    },
    /**
       * Returns the selected scenario object.
       */
    scenario () {
      return this.scenarios.find(s => s.id === this.scenarioId);
    },
    /**
       * Returns the selected economical indicator object.
       */
    indicator () {
      return this.indicators.find(s => s.id === this.indicatorId);
    },
    /**
       * Returns the type of category selection, which depends on the selected indicator.
       * Can be either 'goods' or 'industry'.
       */
    categoryType () {
      return this.indicator?.categoryType;
    },
    /**
       * Returns the selected category object.
       */
    category () {
      return this.categories[this.categoryType].find(s => s.id === this.categoryId);
    }
  },

  watch: {
    /**
       * Watch for scenarioId selection change.
       */
    scenarioId (newVal) {
      this.update();
    },
    /**
       * Watch for economical indicator selection change.
       * Remarks: Here we have to clear out the category, if the category type
       *  has changed.
       */
    indicatorId (newVal, oldVal) {
      const newCategory = this.indicators.find(s => s.id === newVal)?.categoryType;
      const oldCategory = this.indicators.find(s => s.id === oldVal)?.categoryType;
      if (newCategory !== oldCategory) {
        this.categoryId = undefined;
      }

      this.update();
    },
    /**
       * Watch for category selection change.
       */
    categoryId (newVal) {
      this.update();
    },
    /**
       * Request the result after a region selection has been completed.
       */
    selectedRegions () {
      const feature = this.selectedRegions?.length
        ? this.selectedRegions[0]
        : null;
      this.requestedRegion = feature;
      this.update();
    },
    /**
       * Watch for an alert code to be set to display an alert message.
       */
    alertCode () {
      this.alertVisible = !!this.alertCode;
    },
    /**
       * If the user dismisses an alert, reset the alert code,
       * so it can be displayed on the next occasion again.
       */
    alertVisible () {
      if (!this.alertVisible) {
        this.alertCode = null;
      }
    },
    /**
       * Watch for locale changes.
       */
    '$i18n.locale': function () {
      this.getIndicators();
      this.getCategories();
      this.getCategoryTypes();
      this.getEffectTypes();
    }
  }
}
</script>
