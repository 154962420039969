<template>
  <v-chart class="chart"
    autoresize
    :option="getChartOption()"
    :loading="loading"
    :theme="isDarkTheme? 'dark' : 'light'"
    :update-options="getUpdateOptions()"
    :style="chartStyle"
    :group="group"
  />
</template>

<script>
import { ColorTheme } from '@Wegue/mixins/ColorTheme';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  DataZoomComponent
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  DataZoomComponent,
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent
]);

export default {
  name: 'dss-lowwater-barchart',
  components: {
    VChart
  },
  mixins: [ColorTheme],
  props: {
    /**
     * Data source containing the actual data for the chart.
     */
    dataSource: { type: Array, required: true },
    /**
     * Columns from the data source to render the series.
     */
    seriesColumns: { type: Array, required: true },
    /**
     * Name of the label column of the data set.
     */
    labelColumn: { type: String, required: true },
    /**
     * Show the loading indicator.
     */
    loading: { type: Boolean, required: false, default: false },
    /**
     * Chart group ID for connecting charts.
     */
    group: { type: String, required: false, default: undefined },
    /**
     * List of colors to be used for the chart.
     */
    colors: { type: Array, required: true, default: undefined }
  },
  data () {
    return {
      size: {
        top: 10,
        bottom: 10,
        legendRowHeight: 30,
        legendGap: 10,
        barHeight: 20,
        barGap: 7,
        yAxisHeight: 20
      }
    }
  },
  methods: {
    /**
     * Returns the apache echart update options.
     * This is required to work around bugs when removing series - see
     * https://github.com/apache/echarts/issues/6202
     */
    getUpdateOptions () {
      return {
        replaceMerge: ['series']
      };
    },

    /**
     * Returns the apache echart configuration.
     */
    getChartOption () {
      return {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          confine: true,
          valueFormatter: p => {
            return this.$t('dss-lowwater.barchartTooltip', [p.toFixed(2)]);
          }
        },
        legend: {
          left: '3%',
          bottom: this.size.bottom
        },
        dataset: {
          dimensions: [this.labelColumn, ...this.seriesColumns],
          source: this.dataSource
        },
        color: this.colors,
        grid: {
          top: this.size.top,
          bottom: this.size.bottom + this.legendHeight,
          left: '4%',
          right: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: this.$t('dss-lowwater.barchartAxisLabel', ['{value}']),
            hideOverlap: true
          },
          splitNumber: 2
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            interval: 0,
            overflow: 'truncate',
            width: 80
          }
        },
        // Configure the series for the chart, corresponding to the series column names.
        series: this.seriesColumns.map(value => (
          {
            name: value,
            type: 'bar',
            barWidth: this.size.barHeight,
            barCategoryGap: this.size.barGap,
            encode: {
              x: value,
              y: this.labelColumn
            }
            // TODO Improve the layout of the label, currently disabled.
            /*
            label: {
              position: 'left',
              show: true,
              formatter: p => {
                return this.$t('dss-lowwater.barchartAxisLabel',
                  [p.value[p.dimensionNames[p.encode.x[0]]].toFixed(2)]);
              }
            }
            */
          }))
      };
    }
  },
  computed: {
    /**
     * Workaround to roughly estimate the charts height.
     * Unfortunately e-charts does not support a container wise bottom-up sizing
     * model, therefore the parent container size has to be specified.
     */
    chartStyle () {
      const chartHeight = this.size.top +
        this.legendHeight +
        this.seriesColumns.length * this.dataSource.length * (this.size.barHeight + this.size.barGap) +
        this.size.yAxisHeight +
        this.size.bottom;
      return {
        height: `${chartHeight}px`
      }
    },
    /**
     * Estimate the height of the legend bar.
     * Here we assume, that roughly 2 elements will have space in one row of the legend.
     */
    legendHeight () {
      const series = Array.from(new Set(
        this.dataSource.map((item) => item[this.seriesColumn])
      ));
      return Math.round(series.length / 2) * this.size.legendRowHeight +
        this.size.legendGap;
    }
  }
}
</script>
