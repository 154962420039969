import WFSRequest from '../../util/WFSRequest';
import TileWmsSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import {
  and as andFilter,
  equalTo as equalToFilter
} from 'ol/format/filter';

export default class LowWaterController {
  /**
     * Geoserver service configuration
     */
  serviceConfig = null;

  /**
     * Open Layers map.
     */
  map = null;

  /**
     * Map layer to display results on a regional level.
     */
  wmsLayerRegion = null;

  /**
     * Construction of the controller.
     * @param {ol.Map} map OpenLayers map
     * @param {Object} serviceConfig Configuration properties of the controller.
     */
  constructor (map, serviceConfig) {
    this.map = map;
    this.serviceConfig = serviceConfig;
  }

  /**
     * Request the relative economical changes aggregated by category for the
     * currently selected scenarios and indicator.
     * @param {Object} scenario The scenario info.
     * @param {Object} scenarioCmp The scenario info to compare against.
     * @param {Object} indicator The indicator info.
     * @param {axios.CancelTokenSource} cancelTokenSrc An optional cancel token to abort the request.
     */
  requestCategoryResult (scenario, scenarioCmp, indicator, cancelTokenSrc) {
    const me = this;
    return new Promise((resolve, reject) => {
      // Send the request
      WFSRequest.send(me.serviceConfig.baseUrl + '/ows', {
        featureTypes: ['v_category_economics_rel'],
        outputFormat: 'application/json',
        filter: andFilter(
          equalToFilter('id_scen', scenario.id),
          equalToFilter('id_scen_cmp', scenarioCmp.id),
          equalToFilter('id_indicator', indicator.id)
        )
      }, null, cancelTokenSrc)
        .then(function (response) {
          const features = response.data.features;
          resolve(me.processFeatures(features));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  /**
     * Request the relative economical changes aggregated by region for the
     * currently selected scenarios and indicator.
     * Remarks: This is currently not in use.
     * @param {Object} scenario The scenario info.
     * @param {Object} scenarioCmp The scenario info to compare against.
     * @param {Object} indicator The indicator info.
     * @param {axios.CancelTokenSource} cancelTokenSrc An optional cancel token to abort the request.
     */
  requestRegionResult (scenario, scenarioCmp, indicator, cancelTokenSrc) {
    const me = this;
    return new Promise((resolve, reject) => {
      // Send the request
      WFSRequest.send(me.serviceConfig.baseUrl + '/ows', {
        featureTypes: ['fv_region_economics_rel'],
        outputFormat: 'application/json',
        filter: andFilter(
          equalToFilter('id_scen', scenario.id),
          equalToFilter('id_scen_cmp', scenarioCmp.id),
          equalToFilter('id_indicator', indicator.id)
        )
      }, null, cancelTokenSrc)
        .then(function (response) {
          const features = response.data.features;
          resolve(me.processFeatures(features));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  /**
     * Request the relative economical changes for the currently selected
     * scenarios, indicator and either category or region.
     * @param {Object} scenario The scenario info.
     * @param {Object} scenarioCmp The scenario info to compare against.
     * @param {Object} indicator The indicator info.
     * @param {Object} category The optional category info.
     * @param {ol.feature} region The optional feature of the selected region.
     * @param {axios.CancelTokenSource} cancelTokenSrc An optional cancel token to abort the request.
     */
  requestResult (scenario, scenarioCmp, indicator, category, region, cancelTokenSrc) {
    const me = this;
    return new Promise((resolve, reject) => {
      // Setup optional filters, which can be either category or region
      const optFilters = [];
      if (category) {
        optFilters.push(
          equalToFilter('id_category', category.id)
        );
      }
      if (region) {
        const regionId = parseInt(region.getId().split('.')[1]);
        optFilters.push(
          equalToFilter('id_region', regionId)
        );
      }

      // Send the request
      WFSRequest.send(me.serviceConfig.baseUrl + '/ows', {
        featureTypes: ['fv_economics_rel'],
        outputFormat: 'application/json',
        filter: andFilter(
          equalToFilter('id_scen', scenario.id),
          equalToFilter('id_scen_cmp', scenarioCmp.id),
          equalToFilter('id_indicator', indicator.id),
          ...optFilters
        )
      }, null, cancelTokenSrc)
        .then(function (response) {
          const features = response.data.features;
          resolve(me.processFeatures(features));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  /**
     * Postprocess WFS response to return an ordered array of result values.
     * @param {ol.feature[]} features
     */
  processFeatures (features) {
    if (!features) {
      return [];
    }
    return features.map(feat => feat.properties);
  };

  /**
     * Creates the layer displaying relative economical changes on a regional
     * level.
     * @param {String} layer The layer to display.
     * @param {Object} scenario The scenario info.
     * @param {Object} scenarioCmp The scenario info to compare against.
     * @param {Object} indicator The indicator info.
     * @param {Object} category The optional category info.
     * @param {ol.feature} region The optional feature of the selected region.
     */
  createMapLayers (layer, scenario, scenarioCmp, indicator, category, region) {
    const source = new TileWmsSource({
      url: this.serviceConfig.baseUrl + '/wms',
      params: {
        LAYERS: layer,
        TILED: true,
        CQL_FILTER: this.toCQLFilter(scenario, scenarioCmp, indicator, category, region)
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous'
    });
    this.wmsLayerRegion = new TileLayer({
      lid: 'dss-lowwater-economical-layer',
      source,
      opacity: 0.8,
      opacityControl: true,
      zIndex: 1,
      legend: true
    });

    this.map.addLayer(this.wmsLayerRegion);
  };

  /**
     * Removes the layer displaying relative economical changes on a regional
     * level.
     */
  removeMapLayers () {
    if (this.wmsLayerRegion) {
      this.map.removeLayer(this.wmsLayerRegion);
    }
  };

  /**
     * Update the layer displaying relative economical changes on a regional
     * level, after the selected properties changes.
     * @param {String} layer The layer to display.
     * @param {Object} scenario The scenario info.
     * @param {Object} scenarioCmp The scenario info to compare against.
     * @param {Object} indicator The indicator info.
     * @param {Object} category The optional category info.
     * @param {ol.feature} region The optional feature of the selected region.
     */
  updateMapLayers (layer, scenario, scenarioCmp, indicator, category, region) {
    if (this.wmsLayerRegion) {
      const source = this.wmsLayerRegion.getSource();
      const params = source.getParams();
      source.updateParams({
        ...params,
        LAYERS: layer,
        CQL_FILTER: this.toCQLFilter(scenario, scenarioCmp, indicator, category, region)
      });
    }
  };

  /**
     * Compute a CQL filter from the given params.
     * @param {Object} scenario The scenario info.
     * @param {Object} scenarioCmp The scenario info to compare against.
     * @param {Object} indicator The indicator info.
     * @param {Object} category The optional category info.
     * @param {ol.feature} region The optional feature of the selected region.
     */
  toCQLFilter (scenario, scenarioCmp, indicator, category, region) {
    const filters = {
      id_scen: scenario.id,
      id_scen_cmp: scenarioCmp.id,
      id_indicator: indicator.id
    };
    // Setup optional filters, which can be either category or region
    if (category) {
      filters.id_category = category.id
    }
    if (region) {
      const regionId = parseInt(region.getId().split('.')[1]);
      filters.id_region = regionId;
    }

    return Object.entries(filters)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join(' AND ')
  }
}
