// Authors: S.Bechtold, F.Schmenger
import { ImageResource } from "./ImageResource";
import { Vec2d } from "../util/Vec2d";
/**
 * An image resource containing additional postprocessing information for flow
 * layer draw operations.
 * @internal
 */
export class FlowImageResource extends ImageResource {
    constructor() {
        super(...arguments);
        /**
         * A canvas containing the loaded image.
         * Remarks: Default/initial canvas size is 300x150.
         */
        this._canvas = document.createElement("canvas");
        /**
         * Rendering context associated with the canvas.
         */
        this._ctx = (this._canvas.getContext("2d"));
    }
    /**
     * A sparse buffer containing references to the data pixels only.
     */
    get sparseBuffer() {
        if (!this._sparseBuffer) {
            this._sparseBuffer = this.calculateSparseBuffer();
        }
        return this._sparseBuffer;
    }
    /**
     * The image has finished loading.
     * @override
     */
    onLoad() {
        // Set canvas size to image size:
        this._canvas.width = this.width;
        this._canvas.height = this.height;
        // Draw image to canvas:
        this._ctx.drawImage(this._loaderImage, 0, 0);
        // Copy image from canvas to imageData:
        this._imageRawData = this._ctx.getImageData(0, 0, this._canvas.width, this._canvas.height).data;
        super.onLoad();
    }
    /**
     * Get a pixel RGBA color value from the image.
     * @param x X coordinate of the pixel.
     * @param y Y coordinate of the pixel.
     */
    getPixel(x, y) {
        x = Math.round(x);
        y = Math.round(y);
        if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
            return null;
        }
        const i = y * this.width * 4 + x * 4;
        return this._imageRawData.slice(i, i + 4);
    }
    /**
     * Calculates a sparse buffer from the image data. The sparse buffer
     * contains references to the data pixels only.
     */
    calculateSparseBuffer() {
        const buf = [];
        const h = this.height, w = this.width;
        let i = 0;
        for (let y = 0; y < h; ++y) {
            for (let x = 0; x < w; ++x, i += 4) {
                //Remarks: The following operation is a very crude optimization
                // for FlowImageResource.isDataPixel(this.getPixel(x,y)).
                if (this._imageRawData[i]) {
                    buf.push(new Vec2d(x, y));
                }
            }
        }
        return buf;
    }
    /**
     * Check if the pixel corresponds to a no data value. This is determined by the velocity channel
     * being 0.
     * @param color The pixel RGB value.
     */
    static isDataPixel(color) {
        return !(color == null || color[0] === 0);
    }
    /**
     * Decode a pixel into a direction value.
     * Remarks: The division of the original "direction in degrees" value "pixel[1]"" by 0.71
     * maps this value from its [0,255] (8 bit) range to the [0,360] (degrees) range of a full circle.
     * When the image is created on the server, the inverse calculation is performed to map the
     * direction angle value from its [0,360] degrees range to the [0,255] range of the 8-bit
     * "green" channel of the 24-bit RGB image.
     * @param color The pixel RGB value.
     */
    static getDirection(color) {
        return (color[1] / 0.71 + 90) * (Math.PI / 180);
    }
    /**
     * Decode a pixel into a speed value
     * @param color The pixel RGB value.
     */
    static getSpeed(color) {
        return color[0] / 50;
    }
}
