// Authors: S.Bechtold, F.Schmenger
export class Vec2d {
    constructor(x, y) {
        this.x = 0;
        this.y = 0;
        this.x = x;
        this.y = y;
    }
    add(v) {
        return new Vec2d(this.x + v.x, this.y + v.y);
    }
    copy() {
        return new Vec2d(this.x, this.y);
    }
    distance(v) {
        return v.subtract(this).norm();
    }
    mult(scalar) {
        return new Vec2d(this.x * scalar, this.y * scalar);
    }
    norm() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    normalize() {
        const norm = this.norm();
        return new Vec2d(this.x / norm, this.y / norm);
    }
    subtract(v) {
        return new Vec2d(this.x - v.x, this.y - v.y);
    }
    toString() {
        return "(" + this.x + ", " + this.y + ")";
    }
    scalarMult(a) {
        return new Vec2d(this.x * a, this.y * a);
    }
}
