// Authors: S.Bechtold, F.Schmenger
import { LayerState } from "./AbstractCanvasLayer";
import { AbstractWmsCanvasLayer } from "./AbstractWmsCanvasLayer";
import { ImageResource } from "../util/ImageResource";
import { ImageState } from "../util/ImageResource";
import { mod } from "../util/Utils";
/**
 * Base class for an animation layer, using GeoServer as a data source.
 * @abstract
 * @internal
 */
export class AbstractGeoServerAnimationLayer extends AbstractWmsCanvasLayer {
    /**
     * Construction.
     * @param options Optional layer options.
     * @constructor
     * @internal
     */
    constructor(options) {
        super(options);
        /**
         * Animation frame data of the currently loaded animation.
         */
        this._animFrames = [];
        /**
         * Index of the currently displayed animation frame.
         */
        this._currentFrameIndex = 0;
        /**
         * Image pool to use for frame loading.
         */
        this._imagePool = null;
        /**
         * Return a number to prioritize image requests. Priority is a unit-less
         * value  where lower values represent higher priority.
         * @param image The image resource.
         */
        this.imageLoadPriority = (image) => {
            const index = this._animFrames.indexOf(image);
            if (index === -1) {
                console.error("Image not found in animation frames.");
                return 0.0;
            }
            return mod(index - this._currentFrameIndex, this._animFrames.length);
        };
    }
    /**
     * Set the animation meta info object.
     * @internal
     */
    set animation(value) {
        if (this._animation !== value) {
            this.setState(LayerState.Initializing);
        }
        this._animation = value;
    }
    /**
     * Set the current frame index and redraw the layer.
     * If the new frame isn't loaded yet, suspend the animation.
     * @internal
     */
    set currentFrameIndex(val) {
        this._currentFrameIndex = val;
        if (this._state !== LayerState.Ready) {
            return;
        }
        const state = this._animFrames[this._currentFrameIndex].state;
        if (state !== ImageState.Loaded && state !== ImageState.Error) {
            this.setState(LayerState.Loading);
            return;
        }
        this.onFrameChange();
    }
    /**
     * Set the Image pool to use for frame loading.
     * @internal
     */
    set imagePool(imagePool) {
        this._imagePool = imagePool;
    }
    /**
     * Returns true if the layer has finished loading images.
     * @api
     */
    get isReady() {
        return !this.getVisible() || this._state === LayerState.Ready;
    }
    /**
     * Create an image resource for loading images.
     * This will be overridden in derived classes.
     * @param url The URL to request.
     */
    createImageResource(url) {
        return new ImageResource(url, this.imageLoadPriority);
    }
    /**
     * Force a redraw after the animation frame changed.
     * This will be overridden in derived classes.
     */
    onFrameChange() {
        // ATTENTION: We MUST use this.getSource().changed() here,
        // and not window.requestAnimationFrame(this.draw)!
        // Use of the latter would cause wrong screen coordinates!
        this.getSource().changed();
    }
    /**
     * Initialize the layer and asynchronously load the animation frames for this layer.
     * @override
     */
    init() {
        // Clear out the canvas.
        this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
        // The layer is not ready to load, if projection or visible extent are
        // not yet initialized or the animation meta info or image pool are not
        // set. This is a paranoia check and is supposed to no longer happen.
        if (!this._proj ||
            !this._visibleExtent ||
            !this._animation ||
            !this._imagePool) {
            return;
        }
        // Update the loading state and fire an image load event for our source.
        this.setState(LayerState.Loading);
        // Remove all previously requested frames from the image pool and
        // clear the internal animation frame list.
        for (const image of this._animFrames) {
            this._imagePool.removeImage(image);
        }
        this._animFrames = [];
        // Build frame list.
        let frameList = [];
        const pub = this._animation.publishables.published;
        if (Array.isArray(pub)) {
            frameList = pub;
        }
        else {
            frameList = [pub];
        }
        // Add WMS images to the image pool and the internal animation frame
        // list.
        for (const frameLayerInfo of frameList) {
            const layer = frameLayerInfo.name.indexOf(":") === -1
                ? this._animation.workspace.name + ":" + frameLayerInfo.name
                : frameLayerInfo.name;
            const url = this._animation.geoServerUrl +
                "/wms?" +
                this.getWmsQueryString(layer, "image/png", true);
            const image = this.createImageResource(url);
            this._animFrames.push(image);
            this._imagePool.addImage(image);
        }
    }
    /**
     * Check if the layer is in the process of loading images and update the
     * internal loading state.
     * @param numPreload Number of frames that should be buffered.
     * @internal
     */
    updateLoading(numPreload) {
        if (this._state !== LayerState.Loading) {
            return;
        }
        for (let i = 0; i < numPreload; ++i) {
            const j = mod(this._currentFrameIndex + i, this._animFrames.length);
            const state = this._animFrames[j].state;
            if (state !== ImageState.Loaded && state !== ImageState.Error) {
                return;
            }
        }
        this.setState(LayerState.Ready);
    }
}
