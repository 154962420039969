import TileWmsSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';

export default class DamageBaseController {
  /**
    * Geoserver service configuration.
    */
  serviceConfig = null;

  /**
    * Geoserver service configuration for services used in base controller.
    */
  serviceBaseConfig = null;

  /**
    * Open Layers map.
    */
  map = null;

  /**
    * Map layer displaying inundation levels.
    */
  wmsLayerInundation = null;

  /**
   * Map layer displaying damage.
   */
  wmsLayerDamage = null;

  /**
   * Map layer displaying asset values.
   */
  wmsLayerValue = null;

  /**
    * Construction of the controller.
    * @param {ol.Map} map OpenLayers map
    * @param {Object} serviceConfig Configuration properties of the controller.
    * @param {Object} serviceBaseConfig Configuration properties of the base
    *   controller.
    */
  constructor (map, serviceConfig, serviceBaseConfig) {
    this.map = map;
    this.serviceConfig = serviceConfig;
    this.serviceBaseConfig = serviceBaseConfig;
  }

  /**
    * Create the layer displaying inundation levels.
    * Remarks: The layer is currently managed by the BEAM data model,
    * even though it is global to both building and BEAM damage.
    * @param {Object} scenario The scenario info.
    */
  createMapLayer (scenario) {
    const source = new TileWmsSource({
      url: this.serviceBaseConfig.baseUrl + '/wms',
      params: {
        LAYERS: `flood_beam:${scenario.exp_layer}`,
        TILED: true
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous'
    });
    this.wmsLayerInundation = new TileLayer({
      lid: 'dss-flooddamage-inundation-layer',
      source,
      opacity: 0.8,
      opacityControl: true,
      // hoverable: true,
      // hoverOverlay: 'dss-flooddamage-inundation-hover-tooltip',
      legend: true,
      legendOptions: {
        width: 14
      }
    });

    this.map.addLayer(this.wmsLayerInundation);
  }

  /**
    * Remove the layer displaying inundation levels.
    */
  removeMapLayer () {
    if (this.wmsLayerInundation) {
      this.map.removeLayer(this.wmsLayerInundation);
    }
    if (this.wmsLayerValue) {
      this.map.removeLayer(this.wmsLayerValue);
    }
    if (this.wmsLayerDamage) {
      this.map.removeLayer(this.wmsLayerDamage);
    }
  }

  /**
    * Update the layer displaying inundation levels, after
    * the selected scenario changes.
    * @param {Object} scenario The scenario info.
    */
  updateMapLayer (scenario) {
    if (this.wmsLayerInundation) {
      const source = this.wmsLayerInundation.getSource();
      const params = source.getParams();
      source.updateParams({
        ...params,
        LAYERS: `flood_beam:${scenario.exp_layer}`
      });
    }
  }
}
