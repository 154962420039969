// Authors: S.Bechtold, F.Schmenger
/**
 * GeoServer REST API wrapper.
 * @internal
 */
export class GeoServerRestApi {
    /**
     * Construction.
     * @param geoserverUrl Base URL to the GeoServer.
     * @param user Optional GeoServer user. For security reasons use during
     *  development only!
     * @param password Optional GeoServer password. For security reasons use
     *  during development only!
     * @constructor
     */
    constructor(geoserverUrl, user, password) {
        this._geoserverUrl = geoserverUrl;
        this._user = user;
        this._password = password;
        if (this._user || this._password) {
            console.warn("Geoserver credentials have been configured.");
            console.warn("For security reasons use during development only!");
            console.warn("For productive use consider configuring a Geoserver proxy.");
        }
    }
    /**
     * Returns the base URL to the GeoServer.
     */
    get geoServerUrl() {
        return this._geoserverUrl;
    }
    /**
     * Returns the GeoServer user.
     */
    get user() {
        return this._user;
    }
    /**
     * Returns the GeoServer password.
     */
    get password() {
        return this._password;
    }
    /**
     * Retrieves a single layer definition.
     * @param workspace The name of the workspace containing the layer.
     * @param name The name of the layer to retrieve.
     */
    getLayer(workspace, name) {
        return new Promise((resolve, reject) => {
            const url = "/rest/layers/" + workspace + ":" + name + ".json";
            this.asyncLoad(url).then((response) => {
                const argument = response ? response.layer : null;
                resolve(argument);
            }, () => {
                reject("Failed to get layer " + url);
            });
        });
    }
    /**
     * Retrieves a single layer group definition.
     * @param workspace The name of the workspace containing the layer group.
     * @param name The name of the layer group to retrieve.
     */
    getLayerGroup(workspace, name) {
        return new Promise((resolve, reject) => {
            const url = !workspace
                ? "/rest/layergroups/" + name + ".json"
                : "/rest/workspaces/" + workspace + "/layergroups/" + name + ".json";
            this.asyncLoad(url).then((response) => {
                const argument = response ? response.layerGroup : null;
                resolve(argument);
            }, () => {
                reject("Failed to get layer group " + url);
            });
        });
    }
    /**
     * Returns a list of layer groups.
     * @param workspace The name of the workspace containing the layer groups.
     *  If null, then all groups on the server not otherwise in a workspace
     *  are returned.
     */
    getLayerGroups(workspace) {
        return new Promise((resolve, reject) => {
            const url = !workspace
                ? "/rest/layergroups.json"
                : "/rest/workspaces/" + workspace + "/layergroups.json";
            this.asyncLoad(url).then((response) => {
                const argument = response.layerGroups.layerGroup
                    ? response.layerGroups.layerGroup
                    : [];
                resolve(argument);
            }, () => {
                reject("Failed to get layer groups " + url);
            });
        });
    }
    /**
     * Returns a list of all layers on the server.
     */
    getLayers() {
        return new Promise((resolve, reject) => {
            const url = "/rest/layers.json";
            this.asyncLoad(url).then((response) => {
                const argument = response.layers.layer ? response.layers.layer : [];
                resolve(argument);
            }, () => {
                reject("Failed to get layers " + url);
            });
        });
    }
    /**
     * Returns a list of all workspaces on the server.
     */
    getWorkspaces() {
        return new Promise((resolve, reject) => {
            const url = "/rest/workspaces.json";
            this.asyncLoad(url).then((response) => {
                const argument = response.workspaces.workspace
                    ? response.workspaces.workspace
                    : [];
                resolve(argument);
            }, () => {
                reject("Failed to get workspaces " + url);
            });
        });
    }
    /**
     * Returns a list of all workspaces on the server starting with the given
     * prefix.
     * @param prefixes An array of workspace name prefixes.
     */
    getWorkspacesByPrefix(prefixes) {
        return new Promise((resolve, reject) => {
            this.getWorkspaces().then((workspaces) => {
                const result = workspaces.filter((ws) => {
                    for (const prefix of prefixes) {
                        if (ws.name.startsWith(prefix)) {
                            return true;
                        }
                    }
                    return false;
                });
                resolve(result);
            }, () => {
                reject("Failed to get workspaces by prefix");
            });
        });
    }
    /**
     * Asynchronous XHR wrapper.
     * @param relUrl URL relative to GeoServer base URL.
     */
    asyncLoad(relUrl) {
        return new Promise((resolve, reject) => {
            const url = this._geoserverUrl + relUrl;
            const request = new XMLHttpRequest();
            request.open("GET", url, true);
            if (this._user || this._password) {
                request.setRequestHeader("Authorization", "Basic " + btoa(this._user + ":" + this._password));
            }
            request.onload = () => {
                if (request.status >= 400) {
                    reject(request);
                    return;
                }
                try {
                    const obj = JSON.parse(request.responseText);
                    resolve(obj);
                }
                catch (e) {
                    console.error("Exception while parsing JSON: " + e);
                    reject(request);
                }
            };
            request.onerror = () => {
                console.error("HTTP request failed: " + request.statusText, request.responseText);
                reject(request);
            };
            request.send();
        });
    }
}
