<!--
A feature selection control with map interaction for filtering.
-->
<template>
  <v-row class="fill-height shrink" align="center">
    <v-btn-toggle color="secondary"
      :value="isActiveInternal">
      <v-btn
        :value="true"
        :title="tooltip"
        @click="selectClick">
        <v-icon>{{ icon }}</v-icon>
        <div v-if="text" class="ml-1"> {{ text }} </div>
      </v-btn>
    </v-btn-toggle>

    <!-- TODO review layout of reset button -->
    <v-btn v-if="resetButton"
      class="ml-1"
      icon
      elevation="2"
      :title="resetTooltip"
      @click="resetClick"
    >
      <v-icon>{{ resetIcon }}</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import { Mapable } from '@Wegue/mixins/Mapable';
import FeaturePickerController from './FeaturePickerController';

export default {
  name: 'dss-featurepicker',
  mixins: [Mapable],
  props: {
    /**
       * Button properties.
       */
    icon: { type: String, required: false, default: 'mdi-feature-search' },
    text: { type: String, required: false, default: null },
    tooltip: { type: String, required: false, default: null },
    resetButton: { type: Boolean, required: false, default: false },
    resetIcon: { type: String, required: false, default: 'close' },
    resetTooltip: { type: String, required: false, default: null },

    /**
       * Associated layer.
       */
    layerId: { type: String, required: true },

    /**
       * Activation state and preselected features.
       */
    isActive: { type: Boolean, required: false, default: false },
    value: { type: Array, required: false, default: null },

    /**
       * Parent layer.
       */
    parentLayer: { type: Object, required: false, default: null }
  },
  data () {
    return {
      isActiveInternal: false,
      valueInternal: this.value
    }
  },
  destroyed () {
    this.destroy();
  },
  methods: {
    /**
       * This function is executed, after the map is bound (see mixins/Mapable).
       * Initialize the layers, the underlying interaction and the preselected features.
       */
    onMapBound () {
      this.controller = new FeaturePickerController(this.map, this.onFeatureChange, this.$props)
      this.activate(this.isActive, false);
    },
    /**
       * This function is executed, before the map is unbound (see mixins/Mapable)
       */
    onMapUnbound () {
      this.destroy();
    },
    /**
       * Tears down the selection controller.
       */
    destroy () {
      if (this.controller) {
        this.controller.destroy();
        this.controller = undefined;
      }
    },
    /**
       * Callback function executed when user selects a feature on the map.
       * @param {String} lid The associated layer id.
       * @param {Array<ol.Feature>} features An array of feature objects
       */
    onFeatureChange (features) {
      this.valueInternal = features;
      this.$emit('input', features);
    },
    /**
       * The user has clicked the button to toggle the select operation.
       * Remarks: Two way binding on the 'isActiveInternal' state is avoided, as this will
       *  lead to problems when wrapped by a v-item-group. A 'toggle' event should never
       *  be raised, when the 'isActive' property of the control is altered.
       */
    selectClick () {
      this.activate(!this.isActiveInternal, true);
    },
    /**
       * The user has clicked the button to reset the select operation.
       * Removes the current selection from the map.
       */
    resetClick () {
      this.controller.resetClick();
    },
    /**
       * Start / stop the select interaction by toggling the visibility
       * of the selection layer and listening to the respective selection
       * events.
       */
    activate (active, raiseEvent) {
      if (this.isActiveInternal === active) {
        return;
      }
      this.isActiveInternal = active;
      if (this.controller) {
        this.controller.selectToggled(active);
      }
      if (raiseEvent) {
        this.$emit('toggle', active);
      }
    }
  },
  watch: {
    /**
       * Watch the 'isActive' configuration property.
       */
    isActive (value) {
      this.activate(value, false);
    },
    /**
       * Watch the 'value' configuration property.
       */
    value (value) {
      if (this.valueInternal === value) {
        return;
      }
      if (this.controller) {
        this.controller.setFeatures(value, true);
      }
    }
  }
};
</script>
