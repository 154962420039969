// Authors: S.Bechtold, F.Schmenger
import { AbstractCanvasLayer } from "./AbstractCanvasLayer";
/**
 * Base class for an animation layer, using a GeoServer WMS as a data source.
 * @abstract
 * @internal
 */
export class AbstractWmsCanvasLayer extends AbstractCanvasLayer {
    /**
     * Construction.
     * @param options Optional layer options.
     * @constructor
     */
    constructor(options) {
        super(options);
        /**
         * WMS Styles in which layers are to be rendered. Value is a comma-separated list of style names,
         * or empty if default styling is required. Style names may be empty in the list, to use default layer styling.
         */
        this._styles = "";
        /**
         * WMS image size to canvas size ratio.
         */
        this._wmsImageScaleFactor = 0.5;
        /**
         * Grid interpolation scheme.
         * Remarks: This should be set to 'nearest neighbor' for flow layers to avoid trouble with flow direction decoding.
         */
        this._interpolationMethod = "nearest neighbor";
        if (typeof options.interpolationMethod !== "undefined") {
            this._interpolationMethod = options.interpolationMethod;
        }
        if (typeof options.wmsImageScaleFactor !== "undefined") {
            this._wmsImageScaleFactor = options.wmsImageScaleFactor;
        }
        if (typeof options.styles !== "undefined") {
            this._styles = options.styles;
        }
    }
    /**
     * Set WMS Styles in which layers are to be rendered. Value is a comma-separated list of style names,
     * or empty if default styling is required. Style names may be empty in the list, to use default layer styling.
     * @internal
     */
    set styles(value) {
        this._styles = value;
    }
    /**
     * Formats the query string for a WMS request.
     * @param layers WMS Layers to display on map. Value is a comma-separated list of layer names.
     * @param format Format for the map output. See WMS output formats for supported values.
     * @param transparent Whether the map background should be transparent.
     */
    getWmsQueryString(layers, format, transparent = true) {
        const bbox_string = this._visibleExtent[0] +
            "," +
            this._visibleExtent[1] +
            "," +
            this._visibleExtent[2] +
            "," +
            this._visibleExtent[3];
        // ATTENTION: imgWidth and imgHeight MUST be integer values!
        const wmsImageWidth = Math.round(this._canvas.width * this._wmsImageScaleFactor);
        const wmsImageHeight = Math.round(this._canvas.height * this._wmsImageScaleFactor);
        let result = "&service=WMS&version=1.1.0&request=GetMap";
        result += "&layers=" + layers + "&bbox=" + bbox_string;
        result += "&width=" + wmsImageWidth + "&height=" + wmsImageHeight;
        result +=
            "&srs=" +
                this._proj.getCode() +
                "&format=" +
                format +
                "&transparent=" +
                transparent;
        result += "&styles=" + this._styles;
        if (this._interpolationMethod != "") {
            result += "&interpolations=" + this._interpolationMethod;
        }
        return result;
    }
}
