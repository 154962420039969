<template>
  <portal to="map-overlay">
    <v-container
      fluid class="sticky-bottom">
      <v-row justify="center">
        <v-col cols="10" md="9" lg="7" xl="5">
          <v-toolbar flat dense dark color="secondary">
            <v-btn icon @click="skipBackward" :title="$t('dss-floodsim.skipBackward')"
              :disabled="animationInitializing">
              <v-icon>mdi-skip-backward</v-icon>
            </v-btn>
            <v-btn icon @click="fastBackward" :title="$t('dss-floodsim.fastBackward')"
              :disabled="animationInitializing"
              v-if="smAndUp">
              <v-icon>mdi-rewind-10</v-icon>
            </v-btn>
            <v-btn icon @click="togglePlay"
              :title="animationActive ? $t('dss-floodsim.pause'): $t('dss-floodsim.play')"
              :disabled="animationInitializing">
              <v-icon>{{ animationActive ? "mdi-pause" : "mdi-play" }}</v-icon>
            </v-btn>
            <v-btn icon @click="fastForward" :title="$t('dss-floodsim.fastForward')"
              :disabled="animationInitializing"
              v-if="smAndUp">
              <v-icon>mdi-fast-forward-10</v-icon>
            </v-btn>
            <v-btn icon @click="skipForward" :title="$t('dss-floodsim.skipForward')"
              :disabled="animationInitializing">
              <v-icon>mdi-skip-forward</v-icon>
            </v-btn>
            <v-slider dark hide-details class="px-1"
              color="onsecondary"
              v-model="anim.currentFrameIndex"
              min="0"
              :max="anim.numFramesTotal - 1"
              thumb-label="always"
              :disabled="animationInitializing">
              <template v-slot:thumb-label="{ value }">
                <span class="secondary--text">
                {{ value }}
                </span>
            </template>
            </v-slider>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"
                color="onprimary"
                :title="$t('dss-floodsim.settings')"
                :disabled="animationInitializing">
                  <v-icon>settings</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-on:click="anim.speed=2000">
                  {{ $t('dss-floodsim.speedSlow') }}
                </v-list-item>
                <v-list-item v-on:click="anim.speed=1000">
                  {{ $t('dss-floodsim.speedNormal') }}
                </v-list-item>
                <v-list-item v-on:click="anim.speed=400">
                  {{ $t('dss-floodsim.speedFast') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
  </portal>
</template>

<script>
import { Animation, AnimationState } from '@Libs/olala/olala';

/**
 * UI component for global animation settings.
 */
export default {

  name: 'dss-floodsim-animcontrol',
  props: {
    /**
     * Global animation object.
     */
    animation: { type: Animation, required: true }
  },
  data () {
    return {
      /**
       * True if the animation is playing, false if paused.
       */
      animationActive: true,
      /**
       * Reference to the global animation object, required since
       * the property cannot be mutated.
       */
      anim: this.animation
    }
  },
  /**
   * If the control is opened for the first time, make sure to start the
   * animation to match animationActive state.
   */
  created () {
    this.anim.start();
  },
  methods: {
    /*
     * Toggle play / pausing.
     */
    togglePlay () {
      if (this.animationActive) {
        this.anim.stop();
      } else {
        this.anim.start();
      }
      this.animationActive = !this.animationActive;
    },
    /**
     * Rewinds the animation.
     */
    skipBackward () {
      this.anim.currentFrameIndex = 0;
    },
    /**
     * Skips forwards the animation
     */
    skipForward () {
      this.anim.currentFrameIndex = this.anim.numFramesTotal - 1;
    },
    /**
     * Rewinds the animation by 10 frames.
     */
    fastBackward () {
      this.anim.currentFrameIndex = Math.max(this.anim.currentFrameIndex - 10,
        0);
    },
    /**
     * Fast forwards the animation by 10 frames.
     */
    fastForward () {
      this.anim.currentFrameIndex = Math.min(this.anim.currentFrameIndex + 10,
        this.anim.numFramesTotal - 1);
    }
  },
  computed: {
    /**
     * The animation will not start playing, until it`s initialized.
     * Due to OLALA internals this will also happen when the associated extend
     * is not initially visible on the map.
     */
    animationInitializing () {
      return this.anim.state === AnimationState.Initializing;
    },
    /**
     * Show additional control buttons, if the screen size allows it.
     */
    smAndUp () {
      return this.$vuetify.breakpoint.smAndUp;
    }
  }
}
</script>

<style scoped>
.sticky-bottom {
  position: absolute;
  bottom: 2em;
  z-index: 3;
  pointer-events: none;
}

.sticky-bottom > .row > * {
  pointer-events: auto;
}
</style>
