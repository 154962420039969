import ViewAnimationUtil from '@Wegue/util/ViewAnimation';
import { WguEventBus } from '@Wegue/WguEventBus';
import { unByKey } from 'ol/Observable.js';
import LayerUtil from '@Wegue//util/Layer';
import MapInteractionUtil from '@Wegue/util/MapInteraction';

export default class FeaturePickerController {
  /**
   * The OpenLayers map to create the Feature Picker on.
   */
  map = null;

  /**
   * Callback raised when the selected feature changes.
   */
  featureChangeCallback = null;

  /**
   * Contains the configuration properties of the Feature Picker control.
   */
  config = null;

  /**
   * An OpenLayers features containing the selection.
   */
  selFeatures = null;

  /**
   * The OpenLayers vector layer used for the selection.
   */
  vectorLayer = null;

  /**
   * The OpenLayers select interaction created on the map.
   */
  selectInteraction = null;

  /**
   * The OpenLayers visibility change handler for an optionally associated parent layer.
   */
  parentLayerVisibilityHandler = null;

  /**
   * Selection change event fired by the Wegue select interaction handler.
   */
  selectionChange = null

  /**
   * Construction of the controller.
   * @param {ol.Map} map OpenLayers map
   * @param {function} featureChangeCallback Callback raised when the selected features change.
   * @param {Object} config Configuration properties of the Feature Picker control.
   */
  constructor (map, featureChangeCallback, config) {
    this.map = map;
    this.featureChangeCallback = featureChangeCallback;
    this.config = config;

    this.createMapLayers();
  }

  /**
   * Tears down this controller.
   */
  destroy () {
    this.removeMapLayers();
  }

  /**
   * Obtains the map layer for select interaction.
   */
  createMapLayers () {
    const me = this;
    const config = me.config;

    me.vectorLayer = LayerUtil.getLayerByLid(config.layerId, me.map);
    me.createSelectInteraction();
    me.registerParentLayerChange(config.parentLayer);
    me.setFeatures(config.features, true);
  }

  /**
   * Hides the map layer for select interaction and unregisters events.
   */
  removeMapLayers () {
    const me = this;

    me.removeSelectInteraction();
    me.registerParentLayerChange(null);
    me.vectorLayer = null;
  }

  /**
   * Registers the OpenLayers select interaction.
   */
  createSelectInteraction () {
    const me = this;
    const config = me.config;

    me.selectionChange = function (lid, features) {
      if (lid === config.layerId) {
        me.selFeatures = features;
        me.featureChangeCallback(features);
      }
    };

    me.selectInteraction =
      MapInteractionUtil.getSelectInteraction(me.map, config.layerId);
  }

  /**
   * Unregisters the current OpenLayers select interaction and hides
   * the selection layer.
   */
  removeSelectInteraction () {
    const me = this;
    if (me.vectorLayer) {
      me.vectorLayer.setVisible(false);
      me.vectorLayer = null;
    }
    if (me.selectionChange) {
      WguEventBus.$off('map-selectionchange', me.selectionChange);
      me.selectionChange = null;
    }
    me.selectInteraction = null;
  }

  /**
   * Start / stop the select interaction.
   * @param {Boolean} state True to enable selection, false otherwise.
   */
  selectToggled (state) {
    const me = this;
    me.vectorLayer.setVisible(state);
    if (state) {
      WguEventBus.$on('map-selectionchange', me.selectionChange);
    } else {
      WguEventBus.$off('map-selectionchange', me.selectionChange);
    }
  }

  /**
   * Removes the currently drawn selection from the map.
   */
  resetClick () {
    const me = this;
    me.setFeatures(null, false);
    me.featureChangeCallback(null);
  }

  /**
  * Register a visiblity handler to link the visiblity
  * of the feature picker to the visibility of the parent layer.
  * @param {ol.layer.Layer} newLayer The new parent layer.
  */
  registerParentLayerChange (newLayer) {
    const me = this;
    if (me.parentLayerVisibilityHandler) {
      unByKey(me.parentLayerVisibilityHandler);
      me.parentLayerVisibilityHandler = null;
    }

    if (newLayer) {
      me.vectorLayer.setVisible(newLayer.getVisible());
      me.parentLayerVisibilityHandler =
          newLayer.on('change:visible', me.parentLayerVisibilityChange, me);
    }
  }

  /**
   * The visiblity of the parent layer has changed. Show or hide the current
   * selection.
   * @param {ol.Event} evt Visibility change event
   */
  parentLayerVisibilityChange (evt) {
    const me = this;
    const visible = !evt.oldValue;
    me.vectorLayer.setVisible(visible);
  }

  /**
   * Set a new selection.
   * @param {Array<ol.feature>} features The features to set.
   * @param {Boolean} pan True, to animate to the selection.
   */
  setFeatures (features, pan) {
    const me = this;
    const vectorSource = me.vectorLayer.getSource();

    me.selFeatures = me.selectInteraction.getFeatures();
    me.selFeatures.clear();
    if (features) {
      me.selFeatures.extend(features);

      if (pan) {
        // TODO compute extent from selected features
        const extent = vectorSource.getExtent();
        ViewAnimationUtil.to(me.map.getView(), extent);
      }
    }
  }
};
