var render = function render(){var _vm=this,_c=_vm._self._c;return _c('wgu-module-card',_vm._b({staticClass:"wgu-maprecorder-win",attrs:{"moduleName":_vm.moduleName,"icon":_vm.icon,"width":"350"}},'wgu-module-card',_vm.$attrs,false),[_c('v-expansion-panels',{staticClass:"overflow-y-auto",attrs:{"multiple":true,"accordion":true}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("settings")]),_vm._v(" "+_vm._s(_vm.$t('wgu-maprecorder.options'))+" ")],1)],1),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('wgu-maprecorder.videoFormat')))]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-select',{attrs:{"color":"secondary","item-color":"secondary","menu-props":{
                  'offset-y': true,
                  bottom: true,
                },"items":_vm.mimeTypes,"prepend-icon":"mdi-video-image","dense":"","hide-details":""},model:{value:(_vm.mimeType),callback:function ($$v) {_vm.mimeType=$$v},expression:"mimeType"}})],1),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('wgu-maprecorder.frameRate')))]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-slider',{attrs:{"color":"secondary","prepend-icon":"mdi-application-variable-outline","min":"20","max":"50","step":"1","thumb-label":"","hide-details":""},model:{value:(_vm.frameRate),callback:function ($$v) {_vm.frameRate=_vm._n($$v)},expression:"frameRate"}})],1),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('wgu-maprecorder.bitRate')))]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-slider',{attrs:{"color":"secondary","prepend-icon":"mdi-quality-high","min":"1.0","max":"10.0","step":"0.1","thumb-label":"","hide-details":""},model:{value:(_vm.videoMBitsPerSecond),callback:function ($$v) {_vm.videoMBitsPerSecond=_vm._n($$v)},expression:"videoMBitsPerSecond"}})],1),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('wgu-maprecorder.fileName')))]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"color":"secondary","prepend-icon":"mdi-rename-box","label":"YYYY-MM-DD at HH.MM.SS","dense":"","single-line":"","hide-details":""},model:{value:(_vm.filename),callback:function ($$v) {_vm.filename=$$v},expression:"filename"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-btn',{class:{
          'secondary': true,
          'onsecondary--text': true
        },attrs:{"block":!_vm.recording},on:{"click":_vm.toggleRecord}},[(!_vm.recording)?[_c('v-icon',{attrs:{"left":""}},[_vm._v("fiber_manual_record")]),_vm._v(" "+_vm._s(_vm.$t('wgu-maprecorder.start'))+" ")]:[_c('v-icon',{attrs:{"left":""}},[_vm._v("stop")]),_vm._v(" "+_vm._s(_vm.$t('wgu-maprecorder.stop'))+" ")]],2),_c('v-col',{staticClass:"px-2",attrs:{"align-self":"center"}},[_c('v-progress-linear',{attrs:{"color":"secondary","active":_vm.recording,"buffer-value":"0","stream":""}})],1),_c('v-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('v-alert',{staticClass:"mt-2 mb-0",attrs:{"type":"error","dismissible":"","dense":"","transition":"scroll-y-transition"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.$t('wgu-maprecorder.error'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }