// Authors: S.Bechtold, F.Schmenger
import { GeoServerRestApi } from "./GeoServerRestApi";
/**
 * Utility class to list and load Flood Area animations.
 * @internal
 */
export class GeoServerAnimationApi {
    /**
     * Construction.
     * @param geoserverUrl Base URL to the GeoServer.
     * @param user Optional GeoServer user. For security reasons use during
     *  development only!
     * @param password Optional GeoServer password. For security reasons use
     *  during development only!
     * @constructor
     */
    constructor(geoserverUrl, user, password) {
        this._gsRestApi = new GeoServerRestApi(geoserverUrl, user, password);
    }
    /**
     * Returns an animation meta info JSON object for the respective animation.
     * The method assumes, that the workspace contains exactly 1 layer group
     * matching the workspaces name,which represents the animation meta infos.
     * @param name The name of the animations workspace / layer group.
     */
    getAnimation(name) {
        return new Promise((resolve, reject) => {
            this._gsRestApi.getLayerGroup(name, name).then((result) => {
                if (!result) {
                    reject("Failed to get animation " + name);
                    return;
                }
                resolve(this.processResult(result));
            }, () => {
                reject("Failed to get animation " + name);
            });
        });
    }
    /**
     * Returns all animation meta info JSON objects for animations in
     * workspaces, matching the given prefixes. The method assumes, that each
     * workspace contains exactly 1 layer group matching the workspaces name,
     * which represents the animation meta infos.
     * @param prefixes An array of workspace / layer group name prefixes.
     */
    getAnimations(prefixes) {
        return new Promise((resolve, reject) => {
            this._gsRestApi.getWorkspacesByPrefix(prefixes).then((workspaces) => {
                const result = [];
                const promises = [];
                for (const ws of workspaces) {
                    promises.push(this._gsRestApi.getLayerGroup(ws.name, ws.name));
                }
                Promise.allSettled(promises).then((responses) => {
                    for (const response of responses) {
                        if (response.status !== "fulfilled") {
                            console.warn("Failed to get an animation: " + response.reason);
                            continue;
                        }
                        const lg = response.value;
                        if (!lg) {
                            console.warn("Failed to get an animation, no layer group information");
                            continue;
                        }
                        result.push(this.processResult(lg));
                    }
                    resolve(result);
                }, () => {
                    reject("Failed to get animations");
                });
            }, () => {
                reject("Failed to get animations");
            });
        });
    }
    /**
     * Process the layer group result to add custom properties to the animation
     * meta information.
     * @param result Layer group definition.
     */
    processResult(result) {
        // Add an abstract property.
        try {
            result.abstract = JSON.parse(result.abstractTxt);
        }
        catch (e) {
            console.warn("Failed to parse abstract property of layer group.");
            result.abstract = {};
        }
        // Attach URL of the GeoServer source. This is a bit dirty (S.Bechtold):
        result.geoServerUrl = this._gsRestApi.geoServerUrl;
        return result;
    }
}
