import WFS from 'ol/format/WFS';
import axios from 'axios';
import ParamSerializerUtil from './ParamSerializer';

const WFSRequest = {

  /**
   * Sends a WFS request.
   * This supports formatting of Geoserver custom parameter 'viewParams'.
   * @param {String} url URL
   * @param {Object} options Options for the WFS request.
   * @param {String} wfsVersion WFS protocol version.
   * @param {axios.CancelTokenSource} cancelTokenSrc An optional cancel token to abort the request.
   * @returns {AxiosPromise} The promise returned by axios.
   */
  send (url, options, wfsVersion, cancelTokenSrc) {
    const wfs = new WFS(
      { version: wfsVersion || '1.1.0' }
    );

    const getFeatureOptions = {
      ...options,
      viewParams: ParamSerializerUtil.serializeViewParams(options.viewParams)
    };
    const xmlNode = wfs.writeGetFeature(getFeatureOptions);
    const postData = new XMLSerializer().serializeToString(xmlNode);

    const request = {
      method: 'POST',
      url,
      headers: { 'Content-Type': 'text/xml' },
      data: postData,
      cancelToken: cancelTokenSrc?.token
    };

    return axios(request);
  }
}

export default WFSRequest;
