// Authors: S.Bechtold, F.Schmenger
import { Projection, transformExtent, setProj4, registerProj4 } from "../lib/ES6/OpenLayers";
import { proj4 } from "../lib/ES6/Proj4";
// Make projections defined in proj4 (with proj4.defs()) available in OpenLayers 4.
// For the OpenLayers 5 version the following line is a no-op.
setProj4(proj4);
/**
 * Performs an asnychronous XML HTTP request.
 * @param url The URL to request.
 * @returns A promise containing the response text, which resolves with the
 * request.
 */
function asyncXHR(url) {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.onload = () => {
            const obj = request.responseText;
            resolve(obj);
        };
        request.onerror = () => {
            console.error("HTTP request failed: " + request.statusText + request.responseText);
            reject(request);
        };
        request.send();
    });
}
/**
 * Transform an extent from an arbitrary sourceEpsg by loading up the
 * corresponding proj4 transformation.
 * @param sourceExtent The extent in the original projection.
 * @param sourceEpsg   The original projection of the extent
 * @param destEpsg     The target projection.
 * @returns A promise containing the transformed extent, that resolves
 * with the transformation.
 */
export function asyncTransformExtent(sourceExtent, sourceEpsg, destEpsg) {
    return new Promise(function (resolve, reject) {
        if (sourceEpsg == destEpsg) {
            resolve(sourceExtent);
        }
        const sourceEpsgNumber = sourceEpsg.split(":")[1];
        const url = "https://epsg.io/" + sourceEpsgNumber + ".proj4";
        asyncXHR(url).then(responseText => {
            proj4.defs(sourceEpsg, responseText);
            registerProj4(proj4);
            const sourceProj = new Projection({
                code: sourceEpsg,
                units: "m"
            });
            const destProj = new Projection({ code: destEpsg, units: "m" });
            if (!sourceProj || !destProj) {
                console.error("Something went wrong while transforming extent");
                reject();
                return;
            }
            const transformedExtent = transformExtent(sourceExtent, sourceProj, destProj);
            resolve(transformedExtent);
        }, (request) => {
            console.error("asyncXHR failed: " + request.statusText);
            reject(request);
        });
    });
}
/**
 * Computes n % m avoiding the javascript modulo bug for negative numbers.
 * See https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
 * @param n Divident of the modulo operation.
 * @param m Divisor of the modulo operation.
 */
export function mod(n, m) {
    return ((n % m) + m) % m;
}
