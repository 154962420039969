import TileWmsSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import ParamSerializerUtil from '../../util/ParamSerializer';

export default class HeatController {
  /**
     * Geoserver service configuration
     */
  serviceConfig = null;

  /**
     * Open Layers map.
     */
  map = null;

  /**
     * Map layer to display heat vulnerability.
     */
  wmsLayerVuln = null;

  /**
     * Map layer to display heat exposition.
     */
  wmsLayerExp = null;

  /**
     * Construction of the controller.
     * @param {ol.Map} map OpenLayers map
     * @param {Object} serviceConfig Configuration properties of the controller.
     */
  constructor (map, serviceConfig) {
    this.map = map;
    this.serviceConfig = serviceConfig;
  }

  /**
     * Creates the layers displaying heat exposition and vulnerability.
     * @param {Object} indicator The indicator info.
     */
  createMapLayer (indicator) {
    // Vulnerability layer
    const sourceVuln = new TileWmsSource({
      url: this.serviceConfig.baseUrl + '/wms',
      params: {
        LAYERS: 'heat:fv_indikator_einw',
        TILED: true,
        viewParams: ParamSerializerUtil.serializeViewParams({
          indikator: indicator.column
        }, false)
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous'
    });
    this.wmsLayerVuln = new TileLayer({
      lid: 'dss-heat-vuln-layer',
      source: sourceVuln,
      opacity: 0.8,
      opacityControl: true,
      zIndex: 1,
      hoverable: true,
      hoverOverlay: 'dss-heat-vulnerability-hover-tooltip',
      legend: true
    });
    this.map.addLayer(this.wmsLayerVuln);

    // Exposition layer
    const sourceExp = new TileWmsSource({
      url: this.serviceConfig.baseUrl + '/wms',
      params: {
        LAYERS: 'heat:fc_kenngroessen_baublock',
        TILED: true
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous'
    });
    this.wmsLayerExp = new TileLayer({
      lid: 'dss-heat-exp-layer',
      source: sourceExp,
      opacity: 0.8,
      opacityControl: true,
      legend: true
    });
    this.map.addLayer(this.wmsLayerExp);
  };

  /**
     * Removes the layers displaying heat exposition and vulnerability.
     */
  removeMapLayer () {
    if (this.wmsLayerExp) {
      this.map.removeLayer(this.wmsLayerExp);
    }
    if (this.wmsLayerVuln) {
      this.map.removeLayer(this.wmsLayerVuln);
    }
  };

  /**
     * Update the layer displaying heat vulnerability,
     * after the selected indicator changes.
     * @param {Object} indicator The indicator info.
     */
  updateMapLayer (indicator) {
    if (this.wmsLayerVuln) {
      const source = this.wmsLayerVuln.getSource();
      const params = source.getParams();
      source.updateParams({
        ...params,
        viewParams: ParamSerializerUtil.serializeViewParams({
          indikator: indicator.column
        }, false)
      });
    }
  };
}
