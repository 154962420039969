<!-- TODO: Workaround for poorly designed component interface -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    flat
    color="transparent"
  >

    <!-- NOTE: Apparently, using "v-model" with a computed property doesn't work in the way I expected.
          As a workaround, we use the 'value' attribute and an 'input' event handler that forwards value changes
          to the target object. -->
    <v-subheader>{{ $t('dss-floodsim.particleDensity') }}</v-subheader>
    <v-card-text class="pt-0">
      <v-slider
        color="secondary"
        prepend-icon="mdi-grain"
        :value="animation.particleDensity"
        min="0.0001"
        max="0.1"
        step="0.01"
        thumb-label
        hide-details
        @input="onParticleDensitySliderInput">
      </v-slider>
    </v-card-text>

    <v-subheader>{{ $t('dss-floodsim.traceFadeFactor') }}</v-subheader>
    <v-card-text class="pt-0">
      <v-slider
        color="secondary"
        prepend-icon="mdi-timer-sand"
        v-model.number="animation.traceFadeFactor"
        min="0.5"
        max="0.99"
        step="0.01"
        thumb-label
        hide-details
      />
    </v-card-text>

    <v-subheader>{{ $t('dss-floodsim.particleSpeedScale') }}</v-subheader>
    <v-card-text class="pt-0">
      <v-slider
        color="secondary"
        prepend-icon="mdi-speedometer-medium"
        v-model.number="animation.particleSpeedScale"
        min="0.001"
        max="10"
        step="0.01"
        thumb-label
        hide-details
      />
    </v-card-text>

    <v-subheader>{{ $t('dss-floodsim.traceWidth') }}</v-subheader>
    <v-card-text class="pt-0">
      <v-slider
        color="secondary"
        prepend-icon="mdi-image-size-select-small"
        type="range"
        v-model.number="animation.traceWidth"
        min="0.1"
        max="10"
        step="0.01"
        thumb-label
        hide-details
      />
    </v-card-text>
  </v-card>
</template>

<script>
/* TODO: Workaround for poorly designed component interface */
/* eslint-disable vue/no-mutating-props */
import { Animation } from '@Libs/olala/olala';

/**
 * UI component for customizing animation properties.
 */
export default {
  name: 'dss-floodsim-animoptions',

  props: {
    /**
     * Singleton OLALA animation wrapper.
     */
    animation: { type: Animation, required: true }
  },

  methods: {
    /**
    * Workaround for non-working 'v-model' in combination with a computed property:
    */
    onParticleDensitySliderInput (value) {
      this.animation.particleDensity = value;
    }
  }
}
</script>

<style>
</style>
