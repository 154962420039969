// Authors: S.Bechtold, F.Schmenger
import { getTimestamp } from "./Timestamp";
export var ImageState;
(function (ImageState) {
    ImageState[ImageState["NotRequested"] = 0] = "NotRequested";
    ImageState[ImageState["Loading"] = 1] = "Loading";
    ImageState[ImageState["Loaded"] = 2] = "Loaded";
    ImageState[ImageState["Error"] = 3] = "Error";
})(ImageState || (ImageState = {}));
/**
 * An image resource representing an image element together with it's request
 * handling.
 * @internal
 */
export class ImageResource {
    /**
     * Construction.
     * @param url The URL to request.
     * @param priorityFunction The function that is called to update the image
     *  request's priority.
     * @constructor
     */
    constructor(url, priorityFunction) {
        /**
         * The HTML image element used to request the image.
         */
        this._loaderImage = new Image();
        /**
         * Point of time when sending the request.
         */
        this._requestTimestamp = 0;
        /**
         * Point of time when receiving a request response.
         */
        this._responseTimestamp = 0;
        /**
         * The request state of the image.
         */
        this._state = ImageState.NotRequested;
        this._url = url;
        this._priorityFunction = priorityFunction;
    }
    /**
     * Returns the HTML image element used to request the image.
     */
    get image() {
        return this._loaderImage;
    }
    /**
     * Returns the natural height of the image.
     */
    get height() {
        return this._loaderImage.naturalHeight;
    }
    /**
     * Returns the natural width of the image.
     */
    get width() {
        return this._loaderImage.naturalWidth;
    }
    /**
     * Returns the URL to request.
     */
    get url() {
        return this._url;
    }
    /**
     * Returns the request state of the image.
     */
    get state() {
        return this._state;
    }
    /**
     * Returns the image request time. Currently this includes the time spent
     * during image postprocessing in derived classes.
     */
    get loadTime() {
        if (!this._responseTimestamp || !this._requestTimestamp) {
            return NaN;
        }
        return this._responseTimestamp - this._requestTimestamp;
    }
    /**
     * Returns the resources request priority. Priority is a unit-less value
     * where lower values represent higher priority.
     */
    get priority() {
        return this._priorityFunction ? this._priorityFunction(this) : 0.0;
    }
    /**
     * Cancel a pending load of the image.
     */
    cancelLoading() {
        this._loaderImage.src = "";
        this._state = ImageState.NotRequested;
    }
    /**
     * Request the image. Returns a promise that resolves when the image has
     * been loaded (and postprocessed).
     */
    loadImage() {
        return new Promise((resolve, reject) => {
            // Cancel previous unfinished loading attempt, if exists:
            this.cancelLoading();
            this._loaderImage = new Image();
            // Support cross-origin image loading.
            // ATTENTION: This works only if the server also sends a proper "Access-Control-Allow-Origin" header!
            this._loaderImage.crossOrigin = "Anonymous";
            this._loaderImage.onload = evt => {
                this.onLoad();
                resolve(evt);
            };
            this._loaderImage.onerror = evt => {
                this.onError();
                reject(evt);
            };
            // Start image loading:
            this._state = ImageState.Loading;
            this._requestTimestamp = getTimestamp();
            this._loaderImage.src = this._url;
        });
    }
    /**
     * The image has finished loading.
     * This will be overridden in derived classes.
     */
    onLoad() {
        this._responseTimestamp = getTimestamp();
        this._state = ImageState.Loaded;
    }
    /**
     * Image loading has completed with errors.
     * Remarks: If image loading has been cancelled, the state is set back
     * to NotRequested and is left untouched to distinguish it from a load
     * error.
     */
    onError() {
        if (this._state !== ImageState.Loading) {
            return;
        }
        this._responseTimestamp = getTimestamp();
        this._state = ImageState.Error;
    }
}
