<template>
  <v-row
    align="center"
    class="ma-0"
    >
    <v-col cols="4" class="pa-0">
      {{ title }}
    </v-col>
    <v-rating class="ml-1"
        background-color="primary"
        dense
        small
        :length=length
        :value=value
    />
    <div class="ml-1">
     {{ text }}
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'dss-heat-tooltip-item',
  props: {
    title: { type: String, required: true },
    value: { type: Number, required: true },
    length: { type: Number, required: true },
    text: { type: String, required: true }
  }
}
</script>
