// Authors: S.Bechtold, F.Schmenger
import { LayerState } from "./AbstractCanvasLayer";
import { AbstractGeoServerAnimationLayer } from "./AbstractGeoServerAnimationLayer";
import { ImageState } from "../util/ImageResource";
/**
 * Flood Area raster animation layer, using a GeoServer WMS as a data source.
 * @api
 */
export class WmsRasterAnimationLayer extends AbstractGeoServerAnimationLayer {
    constructor() {
        super(...arguments);
        /**
         * Class name of this layer.
         * @api
         */
        this.className = "WmsRasterAnimationLayer";
        /**
         * Draw the current animation frame to the layers canvas.
         * @override
         */
        this.draw = () => {
            // Clear canvas:
            this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
            // If not all frames are loaded yet, do nothing.
            if (this._state !== LayerState.Ready) {
                return;
            }
            const frame = this._animFrames[this._currentFrameIndex];
            if (frame.state === ImageState.Error) {
                console.warn("Cannot draw raster layer because loading of frame " +
                    this._currentFrameIndex +
                    " failed.");
                return;
            }
            // Draw the animation frame
            try {
                this._ctx.drawImage(frame.image, 0, 0, this._canvas.width, this._canvas.height);
            }
            catch (e) {
                // Failed to draw image.
                console.error("Exception while drawing image: " + e);
            }
        };
    }
}
