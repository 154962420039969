<template>
  <wgu-map-overlay
    overlayId="dss-heat-vulnerability-hover-tooltip"
    :visible=false
    autoPan
    :autoPanDuration="250"
    :offset="[0, 25]"
    positioning="top-center"
    v-slot="{feature}"
  >
    <v-card
      width="350"
      v-if="feature">
      <v-img src="static/icon/heat.jpg" height="100"/>
      <v-card-title>
        <span>
          {{ $t('dss-heat.title') }}
        </span>
      </v-card-title>
      <v-card-subtitle>
        <heat-tooltip-item
          :title="$t('dss-heat.hovercardVulnTotal')"
          :length="Object.keys(vulnLevels).length"
          :value="Number(feature.get('vul_ges'))"
          :text="vulnLevels[feature.get('vul_ges')]"
        />
        <heat-tooltip-item
          :title="$t('dss-heat.hovercardVulnCitizens')"
          :length="Object.keys(vulnLevels).length"
          :value="Number(feature.get('vul_einw'))"
          :text="vulnLevels[feature.get('vul_einw')]"
        />
        <heat-tooltip-item
          :title="$t('dss-heat.hovercardVulnCitizens65')"
          :length="Object.keys(vulnLevels).length"
          :value="Number(feature.get('vul_einw_65'))"
          :text="vulnLevels[feature.get('vul_einw_65')]"
        />
        <heat-tooltip-item
          :title="$t('dss-heat.hovercardVulnInstitutions')"
          :length="Object.keys(vulnLevels).length"
          :value="Number(feature.get('vul_einr'))"
          :text="vulnLevels[feature.get('vul_einr')]"
        />
      </v-card-subtitle>
    </v-card>
  </wgu-map-overlay>
</template>

<script>
import MapOverlay from '@Wegue/components/modulecore/MapOverlay.vue'
import HeeatTooltipItem from './HeatTooltipItem'
export default {
  name: 'dss-heat-vulnerability-hover-tooltip',
  components: {
    'wgu-map-overlay': MapOverlay,
    'heat-tooltip-item': HeeatTooltipItem
  },
  props: {
    /**
     * Display names for the vulnearbility levels enumeration.
     */
    vulnLevels: { type: Object, required: true }
  }
}
</script>
