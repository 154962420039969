// Authors: S.Bechtold, F.Schmenger
import { LayerState } from "./AbstractCanvasLayer";
import { AbstractGeoServerAnimationLayer } from "./AbstractGeoServerAnimationLayer";
import { ImageState } from "../util/ImageResource";
import { FlowImageResource } from "../util/FlowImageResource";
import { Vec2d } from "../util/Vec2d";
/**
 * Helper class to manage an array of flow particles to be drawn onto the
 * layers canvas.
 * @internal
 */
class Particle {
    constructor() {
        /**
         * Direction of the particle in radial units.
         */
        this.direction = 0;
        /**
         * Current speed of the particle in m/s.
         */
        this.speed = 0;
        /**
         * Fade value to manage the lifetime of a particle.
         */
        this.fade = 0;
    }
}
/**
 * Flood Area particle flow animation layer, using a GeoServer WMS as a data
 * source.
 * @api
 */
export class WmsFlowAnimationLayer extends AbstractGeoServerAnimationLayer {
    /**
     * Construction.
     * @param options Optional layer options.
     * @constructor
     * @internal
     */
    constructor(options) {
        super(options);
        /**
         * Class name of this layer.
         */
        this.className = "WmsFlowAnimationLayer";
        /**
         * Maximum amount of flow particles to be rendered on the layer.
         */
        this._maxNumParticles = 30000;
        /**
         * Multiplier for the basic number of particles to keep the amount stable
         * for varying screen and image resolutions.
         */
        this._numParticleMult = 100000;
        /**
         * Base color of the flow particles.
         */
        this._particleColor = "rgba(255,255,255,1)";
        /**
         * A number between 0 and 1 describing the ratio between particles and the
         * amount of valid data pixels to be rendered on the layer. A higher value
         * corresponds to a larger amount of rendered particles.
         */
        this._particleDensity = 0.06;
        /**
         * Multiplier to determine the delta of pixels for a particle on each
         * animation step, representing particle movement. The resulting speed
         * value is proportianal to the water speed at the particles current
         * location.
         */
        this._particleSpeedScale = 1.0;
        /**
         * A value between 0 and 1 describing how quickly particle will be faded
         * out and recycled. A higher value correponds to a longer particle
         * lifetime / particle trail.
         */
        this._traceFadeFactor = 0.95;
        /**
         * Line width in pixels for a particle to be rendered on the layer.
         */
        this._traceWidth = 1.25;
        /**
         * An array of flow particles to be drawn onto the layers canvas.
         */
        this._particles = [];
        /**
         * Draw the current animation frame to the layers canvas.
         * @override
         */
        this.draw = () => {
            // If not all frames are loaded yet, do nothing.
            if (this._state !== LayerState.Ready) {
                return;
            }
            // Fade particle trails.
            const prev = this._ctx.globalCompositeOperation;
            this._ctx.globalCompositeOperation = "destination-in";
            this._ctx.fillStyle = "rgba(0,0,0, " + this._traceFadeFactor + ")";
            this._ctx.fillRect(0, 0, this._canvas.width, this._canvas.height);
            this._ctx.globalCompositeOperation = prev;
            // Loop over particles and draw each one.
            // Ignore pixels with an invalid start point, which may happen after a
            // frame change when particle positions have not been computed yet.
            this._ctx.lineWidth = this._traceWidth;
            this._ctx.strokeStyle = this._particleColor;
            this._ctx.beginPath();
            const r = 1 / this._wmsImageScaleFactor;
            for (const particle of this._particles) {
                if (!particle.start) {
                    continue;
                }
                this._ctx.moveTo(particle.start.x * r, particle.start.y * r);
                this._ctx.lineTo(particle.end.x * r, particle.end.y * r);
            }
            this._ctx.stroke();
        };
        if (typeof options.particleColor !== "undefined") {
            this._particleColor = options.particleColor;
        }
        if (typeof options.particleDensity !== "undefined") {
            this._particleDensity = options.particleDensity;
        }
        if (typeof options.particleSpeedScale !== "undefined") {
            this._particleSpeedScale = options.particleSpeedScale;
        }
        if (typeof options.traceFadeFactor !== "undefined") {
            this._traceFadeFactor = options.traceFadeFactor;
        }
        if (typeof options.traceWidth !== "undefined") {
            this._traceWidth = options.traceWidth;
        }
    }
    /**
     * Returns the current particle base color.
     * @api
     */
    get particleColor() {
        return this._particleColor;
    }
    /**
     * Sets the particle base color.
     * @api
     */
    set particleColor(value) {
        this._particleColor = value;
    }
    /**
     * Returns the current particle density.
     * @api
     */
    get particleDensity() {
        return this._particleDensity;
    }
    /**
     * Sets the particle density.
     * @api
     */
    set particleDensity(value) {
        this._particleDensity = value;
        if (this._state !== LayerState.Ready) {
            return;
        }
        this.initParticles();
    }
    /**
     * Returns the current particle speed multiplier.
     * @api
     */
    get particleSpeedScale() {
        return this._particleSpeedScale;
    }
    /**
     * Sets the particle speed multiplier.
     * @api
     */
    set particleSpeedScale(value) {
        this._particleSpeedScale = value;
    }
    /**
     * Returns the current particle fading speed.
     * @api
     */
    get traceFadeFactor() {
        return this._traceFadeFactor;
    }
    /**
     * Sets the particle fading speed.
     * @api
     */
    set traceFadeFactor(value) {
        this._traceFadeFactor = value;
    }
    /**
     * Returns the current line width in pixels for a particle.
     * @api
     */
    get traceWidth() {
        return this._traceWidth;
    }
    /**
     * Sets the line width in pixels for a particle.
     * @api
     */
    set traceWidth(value) {
        this._traceWidth = value;
    }
    /**
     * Create an image resource for loading images.
     * @param url The URL to request.
     * @override
     */
    createImageResource(url) {
        return new FlowImageResource(url, this.imageLoadPriority);
    }
    /**
     * Adjust the amount of particles and force a redraw after the current frame has changed.
     * @override
     */
    onFrameChange() {
        this.initParticles();
        super.onFrameChange();
    }
    /**
     * Invoked by a timer to perform a simulation step for all particles and
     * redraw the animation frame.
     * @internal
     */
    doParticleAnimationStep() {
        // If not all frames are loaded yet, do nothing.
        if (this._state !== LayerState.Ready) {
            return;
        }
        const frame = this._animFrames[this._currentFrameIndex];
        if (frame.state === ImageState.Error) {
            console.warn("Cannot animate flow layer flow layer because " +
                "loading of frame " +
                this._currentFrameIndex +
                " failed.");
            return;
        }
        const sparseBuffer = frame.sparseBuffer;
        const numDataPixels = sparseBuffer.length;
        // Perform a simulation step for all particles.
        // If a trace has reached its end of life, replace it with a new one.
        // All coordinates are computed with respect to the image size,
        // not canvas size. The "meter per pixel" multiplier is only a rough
        // approximation for non-conformal projections, e.g. EPSG:4326.
        const meterPerPixel = (this._resolution * this._proj.getMetersPerUnit()) /
            this._wmsImageScaleFactor;
        for (const particle of this._particles) {
            if (particle.fade < 0.1) {
                const index = Math.floor(Math.random() * numDataPixels);
                const coords = sparseBuffer[index];
                const pixel = frame.getPixel(coords.x, coords.y);
                particle.start = particle.end = coords;
                particle.fade = Math.random() * 1;
                particle.direction = FlowImageResource.getDirection(pixel);
                particle.speed = FlowImageResource.getSpeed(pixel);
            }
            // Compute the particles next position, which depends on its
            // current direction and speed.
            const pixelSpeed = (particle.speed * this._particleSpeedScale) / meterPerPixel;
            const dx = Math.sin(particle.direction) * pixelSpeed;
            const dy = Math.cos(particle.direction) * pixelSpeed;
            const newEnd = new Vec2d(particle.end.x + dx, particle.end.y + dy);
            let pixel = frame.getPixel(newEnd.x, newEnd.y);
            // If the particle's next postion would be a nodata pixel, let
            // the particle die. The same applies, if the next position would
            // cross the layers image boundary.
            if (!FlowImageResource.isDataPixel(pixel)) {
                particle.speed = 0;
                particle.fade = 0;
            }
            // Otherwise, move the particle forward:
            else {
                pixel = pixel;
                particle.direction = FlowImageResource.getDirection(pixel);
                particle.speed = FlowImageResource.getSpeed(pixel);
                particle.start = particle.end;
                particle.end = newEnd;
                particle.fade *= this._traceFadeFactor;
            }
        }
        // ATTENTION: We MUST use this.getSource().changed() here,
        // and not window.requestAnimationFrame(this.draw)!
        // Use of the latter would cause wrong screen coordinates!
        this.getSource().changed();
    }
    /**
     * Initialize the layer and asynchronously load the animation frames for this layer.
     * @override
     */
    init() {
        this._particles = [];
        super.init();
    }
    /**
     * Adjust the number of particles, typically after a frame change.
     * Remarks: The number of particles depends on the information in the frame
     *  (rate of data-pixels) and the particle density multiplier, but should
     *  not depend on the resolution of WMS images.
     */
    initParticles() {
        const frame = this._animFrames[this._currentFrameIndex];
        if (frame.state === ImageState.Error) {
            console.warn("Cannot init particles for flow layer because " +
                "loading of frame " +
                this._currentFrameIndex +
                " failed.");
            return;
        }
        const dataPixelRatio = frame.sparseBuffer.length / (frame.width * frame.height);
        let numParticles = Math.ceil(dataPixelRatio * this._particleDensity * this._numParticleMult);
        numParticles = Math.min(numParticles, this._maxNumParticles);
        if (numParticles < this._particles.length) {
            this._particles.length = numParticles;
        }
        else {
            let delta = this._particles.length - numParticles;
            while (delta++ < 0) {
                this._particles.push(new Particle());
            }
        }
    }
}
