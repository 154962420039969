<template>
  <v-chart class="chart"
    autoresize
    :option="getChartOption()"
    :loading="loading"
    :theme="isDarkTheme? 'dark' : 'light'"
     :group="group"
  />
</template>

<script>
import { ColorTheme } from '@Wegue/mixins/ColorTheme';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  CanvasRenderer,
  PieChart,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: 'dss-flooddamage-piechart',
  components: {
    VChart
  },
  mixins: [ColorTheme],
  props: {
    /**
     * Data source containing the actual data for the chart.
     */
    dataSource: { type: Array, required: true },
    /**
     * Name of the label column of the data set.
     */
    labelColumn: { type: String, required: true },
    /**
     * Name of the value column of the data set.
     */
    valueColumn: { type: String, required: true },
    /**
     * Show the loading indicator.
     */
    loading: { type: Boolean, required: false, default: false },
    /**
     * Chart group ID for connecting charts.
     */
    group: { type: String, required: false, default: undefined },
    /**
     * List of colors to be used for the chart.
     */
    colors: { type: Array, required: true, default: undefined }
  },
  methods: {
    /**
     * Returns the apache echart configuration.
     */
    getChartOption () {
      return {
        backgroundColor: 'transparent',
        tooltip: {
          confine: true,
          trigger: 'item',
          formatter: p => {
            return this.$t('dss-flooddamage.piechartTooltip',
              [p.marker,
                p.name,
                p.value[this.valueColumn].toFixed(2),
                p.percent]
            );
          }
        },
        legend: {
          show: false
        },
        dataset: {
          dimensions: [this.labelColumn, this.valueColumn],
          source: this.dataSource
        },
        color: this.colors,
        series: [{
          type: 'pie',
          radius: '90%',
          center: ['50%', '50%'],
          minShowLabelAngle: 12,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          encode: {
            itemName: [this.labelColumn],
            value: this.valueColumn
          },
          label: {
            show: true,
            position: 'inside',
            rotate: true,
            formatter: p => {
              return this.$t('dss-flooddamage.piechartSeriesLabel', [p.value[this.valueColumn].toFixed(2)]);
            }
          }
        }]
      };
    }
  }
}
</script>

<style scoped>
.chart {
   height: 350px;
}
</style>
