<template>
  <v-chart class="chart"
    autoresize
    :option="getChartOption()"
    :loading="loading"
    :theme="isDarkTheme? 'dark' : 'light'"
    :update-options="getUpdateOptions()"
    :style="chartStyle"
    :group="group"
  />
</template>

<script>
import { ColorTheme } from '@Wegue/mixins/ColorTheme';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  DataZoomComponent
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  DataZoomComponent,
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent
]);

export default {
  name: 'dss-flooddamage-barchart',
  components: {
    VChart
  },
  mixins: [ColorTheme],
  props: {
    /**
     * Data source containing the actual data for the chart.
     */
    dataSource: { type: Array, required: true },
    /**
     * Name of the series column of the data set.
     */
    seriesColumn: { type: String, required: true },
    /**
     * Name of the label column of the data set.
     */
    labelColumn: { type: String, required: true },
    /**
     * Name of the value column of the data set.
     */
    valueColumn: { type: String, required: true },
    /**
     * Show the loading indicator.
     */
    loading: { type: Boolean, required: false, default: false },
    /**
     * Chart group ID for connecting charts.
     */
    group: { type: String, required: false, default: undefined },
    /**
     * List of colors to be used for the chart.
     */
    colors: { type: Array, required: true, default: undefined }
  },
  data () {
    return {
      size: {
        top: 10,
        bottom: 10,
        legendRowHeight: 30,
        legendGap: 20,
        barHeight: 20,
        barGap: 7,
        yAxisHeight: 20
      }
    }
  },
  methods: {
    /**
     * Returns the apache echart update options.
     * This is required to work around bugs when removing series - see
     * https://github.com/apache/echarts/issues/6202
     */
    getUpdateOptions () {
      return {
        replaceMerge: ['series']
      };
    },

    /**
     * Returns the apache echart configuration.
     */
    getChartOption () {
      return {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: p => {
            return this.$t('dss-flooddamage.barchartTooltip',
              [p.marker,
                p.seriesName,
                this.dataSource[p.dataIndex][this.labelColumn],
                p.value.toFixed(2)]
            );
          }
        },
        legend: {
          left: '3%',
          bottom: this.size.bottom
        },
        color: [null, ...this.colors],
        grid: {
          top: this.size.top,
          bottom: this.size.bottom + this.legendHeight,
          left: '4%',
          right: '22%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: this.$t('dss-flooddamage.barchartAxisLabel', ['{value}']),
            hideOverlap: true
          },
          splitNumber: 2
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            formatter: (p, i) => {
              return this.dataSource[i][this.labelColumn];
            },
            overflow: 'truncate',
            width: 80
          }
        },
        // Remarks: The following will make legends work properly and uses a group based colorization pattern:
        // Create a series for each row in the dataset, while only one column in data per series is populated.
        // A bar gap of -100% is used, which effectively collapses all unused bars.
        // See https://stackoverflow.com/questions/69215766/echarts-separate-series-based-on-dataset-field
        // An additional workaround is to add a spank element as first series, which will internally receive
        // componentIndex 0. This fixes obscure linked tooltip behvahior, when this chart is connected to the
        // pie chart - see https://github.com/apache/echarts/issues/11144.
        series: [{ type: 'bar' },
          ...this.dataSource.map((item, i) => {
            return {
              type: 'bar',
              name: item[this.seriesColumn],
              barWidth: this.size.barHeight,
              barGap: '-100%',
              barCategoryGap: this.size.barGap,
              data: Array.from(this.dataSource,
                v => (v === item) ? v[this.valueColumn] : NaN),
              label: {
                position: 'right',
                show: true,
                formatter: p => {
                  return this.$t('dss-flooddamage.barchartAxisLabel', [p.value.toFixed(2)]);
                }
              }
            };
          })
        ]
      };
    }
  },
  computed: {
    /**
     * Workaround to roughly estimate the charts height.
     * Unfortunately e-charts does not support a container wise bottom-up sizing
     * model, therefore the parent container size has to be specified.
     */
    chartStyle () {
      const chartHeight = this.size.top +
        this.legendHeight +
        this.dataSource.length * (this.size.barHeight + this.size.barGap) +
        this.size.yAxisHeight +
        this.size.bottom;
      return {
        height: `${chartHeight}px`
      }
    },
    /**
     * Estimate the height of the legend bar.
     * Here we assume, that roughly 2 elements will have space in one row of the legend.
     */
    legendHeight () {
      const series = Array.from(new Set(
        this.dataSource.map((item) => item[this.seriesColumn])
      ));
      return Math.round(series.length / 2) * this.size.legendRowHeight +
        this.size.legendGap;
    }
  }
}
</script>
