import ViewAnimationUtil from '@Wegue/util/ViewAnimation';
import DrawInteraction from 'ol/interaction/Draw';
import { unByKey } from 'ol/Observable.js';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { METERS_PER_UNIT } from 'ol/proj/Units';
import { getCenter, getHeight, getWidth } from 'ol/extent';

export default class AreaPickerController {
  /**
   * The OpenLayers map to create the Area Picker on.
   */
  map = null;

  /**
   * Callback raised when the selected area changes.
   */
  areaChangeCallback = null;

  /**
   * Contains the configuration properties of the Area Picker control.
   */
  config = null;

  /**
   * An OpenLayers feature containing the selected area.
   */
  selFeature = null;

  /**
   * The OpenLayers vector layers used to draw the selection.
   */
  vectorLayer = null;

  /**
   * The OpenLayers draw interaction created on the map.
   */
  drawInteraction = null;

  /**
   * The OpenLayers visibility change handler for an optionally associated parent layer.
   */
  parentLayerVisibilityHandler = null;

  /**
   * Construction of the controller.
   * @param {ol.Map} map OpenLayers map
   * @param {function} areaChangeCallback Callback raised when the selected area changes.
   * @param {Object} config Configuration properties of the Area Picker control.
   */
  constructor (map, areaChangeCallback, config) {
    this.map = map;
    this.areaChangeCallback = areaChangeCallback;
    this.config = config;

    this.createMapLayers();
  }

  /**
   * Tears down this controller.
   */
  destroy () {
    this.removeMapLayers();
  }

  /**
   * Creates the map layer for area selection.
   */
  createMapLayers () {
    const me = this;
    const config = me.config;

    const vectorSource = new VectorSource({});
    me.vectorLayer = new VectorLayer({
      lid: 'dss-areapicker-layer-' + (Math.random() * 1000000).toFixed(0),
      langKey: 'dss-areapicker-layer',
      source: vectorSource,
      visible: true,
      displayInLayerList: false,
      zIndex: 15,
      style: me.styleFunction.bind(me)
    });

    me.createDrawInteraction();
    me.registerParentLayerChange(config.parentLayer);
    me.map.addLayer(me.vectorLayer);
    me.setArea(config.area, true);
  }

  /**
   * Removes the map layer for area selection.
   */
  removeMapLayers () {
    const me = this;

    me.removeDrawInteraction();
    me.registerParentLayerChange(null);
    me.map.removeLayer(me.vectorLayer);
    me.vectorLayer = null;
  }

  /**
   * Given a resolution and a unit, this method will return a scale.
   * Similar to the function in BasiGX util\Map.js but returns a scale
   * based on meters rather than inch.
   *
   * @param {Number} scale The resolution ypu wish to have the scale for.
   * @param {String} units The units to get the resoultuion for, typically
   *     the unit of the projection of the map view. Allowed values are
   *     `'degrees'`, `'ft'`, `'m'` or `'us-ft'`
   * @return {Number} The calculated scale.
   */
  getScaleForResolution (resolution, units) {
    const dpi = 90.7142857142857;
    const mpu = METERS_PER_UNIT[units];
    return (resolution * mpu * dpi);
  }

  /**
   * The styling for the vector features.
   * @param {ol.Feature} feature The feature to style.
   */
  styleFunction (feature, resolution) {
    const me = this;
    const config = me.config;

    const styleArea = new Style({
      stroke: new Stroke({
        color: config.areaBorderColor,
        width: 3
      }),
      fill: new Fill({
        color: config.areaFillColor
      })
    });

    if (!config.imageSrc) {
      return [styleArea];
    }

    // If a imageSrc is set, draw the image inside the marked geometry.
    const styleMarker = new Style({
      image: new Icon({
        src: config.imageSrc,
        color: config.imageFillColor,
        opacity: 0.8,
        scale: config.imageScale
      }),
      geometry: function (feat) {
        const extent = feat.getGeometry().getExtent();
        const center = getCenter(extent);
        return new Point(center);
      }
    });

    // If the icon would overlap the selection geometry, then hide the geometry.
    // TODO: This needs to be more generalistic, e.g. respect the icon
    // size and shape of the geometry.
    const units = me.map.getView().getProjection().getUnits();
    const scale = me.getScaleForResolution(resolution, units);
    const geom = feature.getGeometry();
    const extent = geom.getExtent();
    const size = Math.min(getWidth(extent),
      getHeight(extent));

    if (config.imageScale > size / (2 * scale)) {
      return [styleMarker];
    }

    return [styleArea, styleMarker];
  }

  /**
   * Creates an OpenLayers draw interaction to draw a geometry for
   * filtering.
   */
  createDrawInteraction () {
    const me = this;
    const config = me.config;

    const vectorSource = me.vectorLayer.getSource();
    const drawInteraction = new DrawInteraction({
      source: vectorSource,
      type: config.type
    });

    const drawStart = function () {
      vectorSource.clear();
    };

    const drawEnd = function (evt) {
      me.selFeature = evt.feature;
      me.areaChangeCallback(me.getArea());
    };

    drawInteraction.on('drawstart', drawStart);
    drawInteraction.on('drawend', drawEnd);

    me.drawInteraction = drawInteraction;
  }

  /**
   * Removes the current OpenLayers draw interaction.
   */
  removeDrawInteraction () {
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
      this.drawInteraction = undefined;
    }
  }

  /**
   * Start / stop the draw interaction.
   * @param {Boolean} state True to enable drawing, false otherwise.
   */
  drawToggled (state) {
    const me = this;
    if (state) {
      me.map.addInteraction(me.drawInteraction);
    } else {
      me.map.removeInteraction(me.drawInteraction);
    }
  }

  /**
   * Removes the currently drawn selection from the map.
   */
  resetClick () {
    const me = this;
    me.setArea(null, false);
    me.areaChangeCallback(null);
  }

  /**
  * Register a visiblity handler to link the visiblity
  * of the area picker to the visibility of the parent layer.
  * @param {ol.layer.Layer} newLayer The new parent layer.
  */
  registerParentLayerChange (newLayer) {
    const me = this;
    if (me.parentLayerVisibilityHandler) {
      unByKey(me.parentLayerVisibilityHandler);
      me.parentLayerVisibilityHandler = null;
    }

    if (newLayer) {
      me.vectorLayer.setVisible(newLayer.getVisible());
      me.parentLayerVisibilityHandler =
          newLayer.on('change:visible', me.parentLayerVisibilityChange, me);
    }
  }

  /**
   * The visiblity of the parent layer has changed. Show or hide the current
   * selection.
   * @param {ol.Event} evt Visibility change event
   */
  parentLayerVisibilityChange (evt) {
    const me = this;
    const visible = !evt.oldValue;
    me.vectorLayer.setVisible(visible);
  }

  /**
   * Get the selected area.
   * @returns {ol.geom.Geometry} The selected geometry.
   */
  getArea () {
    const me = this;
    return me.selFeature?.getGeometry().clone()
  }

  /**
   * Set a new area selection.
   * @param {ol.geom.Geometry} geom The geometry to set.
   * @param {Boolean} pan True, to animate to the selected area
   */
  setArea (geom, pan) {
    const me = this;
    const vectorSource = me.vectorLayer.getSource();

    vectorSource.clear();

    if (geom) {
      me.selFeature = new Feature({
        geometry: geom
      });
      vectorSource.addFeature(me.selFeature);

      if (pan) {
        const extent = vectorSource.getExtent();
        ViewAnimationUtil.to(me.map.getView(), extent);
      }
    } else {
      me.selFeature = null;
    }
  }
};
