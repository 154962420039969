import Polygon from 'ol/geom/Polygon'
import WKT from 'ol/format/WKT';

const GeometrySerializerUtil = {
  /**
   * Serialize the geometry as polygon in the desired format and projection.
   * This may involve approximations, e.g. when converting circles.
   * https://gis.stackexchange.com/questions/122381/how-to-create-circular-polygon-in-openlayers-3
   * @param {ol.geom.Geometry} source Source geometry
   * @param {ol.format} format The serialization format.
   * @param {ol.proj.Projection} srcProj The original projection of the geometry.
   * @param {ol.proj.Projection} destProj The destination projection.
   * @returns The formatted geometry
   */
  serializeAsPolygon (source, format, srcProj, destProj) {
    const geom = source.clone();

    const polygon = (source.getType() === 'Circle')
      ? Polygon.fromCircle(geom)
      : geom;
    polygon.transform(srcProj.getCode(), destProj || 'EPSG:4326');
    const destFormat = format || new WKT();
    return destFormat.writeGeometry(polygon);
  },

  // TODO: Clean this up by providing a dedicated ol.format ?

  /**
   * Serialize the geometry as polygon in WKT format and the desired projection.
   * The WKT is prefixed by 'SRID=xxxx;'
   * @param {ol.geom.Geometry} source Source geometry
   * @param {ol.proj.Projection} srcProj The original projection of the geometry.
   * @param {ol.proj.Projection} destProj The destination projection.
   */
  serializePostgisWKT (source, srcProj, destProj) {
    const wkt = this.serializeAsPolygon(
      source, new WKT(), srcProj, destProj);
    const srid = destProj.split(':')[1];
    return `SRID=${srid};${wkt}`
  },

  /**
   * Deserialize a geometry with the given format and projection.
   * @param {Document | Node | Object | string} source Formatted geometry
   * @param {ol.format} format The serialization format.
   * @param {ol.proj.Projection} srcProj The projection of the serialized geometry.
   * @param {ol.proj.Projection} destProj The destination projection.
   * @returns The geometry.
   */
  deserialize (source, format, srcProj, destProj) {
    const srcFormat = format || new WKT();
    return srcFormat.readGeometry(source, {
      dataProjection: srcProj || 'EPSG:4326',
      featureProjection: destProj
    });
  }
}

export default GeometrySerializerUtil;
